import React, { useContext, useEffect, useState } from 'react';
import OpeningsTable from '_templates/AnalyticsDashboard/OpeningsTable';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { OPENINGS_TABLE_OPTIONS, ITEM_PER_PAGE } from '_templates/AnalyticsDashboard/constans';
import { analyticWidgets } from 'actions/analyticActions';
import { openingMap } from '_templates/utils';

export const AnalyticsOpeningInfo = () => {
  const { appliedFilters } = useContext(Context);
  const [openings, setOpenings] = useState([]);
  const [tableOpeningsLoading, setTableOpeningsLoading] = useState(false);
  const [tableOpeningsParams, setTableOpeningsParams] = useState(OPENINGS_TABLE_OPTIONS);

  const updateOpeningsTableParams = params => {
    setTableOpeningsParams(p => ({ ...p, ...params }));
  };

  const getOpenings = () => {
    setTableOpeningsLoading(true);
    analyticWidgets(
      'openings',
      {
        ...appliedFilters,
        sorting: [{ field: tableOpeningsParams.sorting.field, order: tableOpeningsParams.sorting.order }],
      },
      null,
      `?limit=${tableOpeningsParams.limit.value}&offset=${tableOpeningsParams.offset}`
    )
      .then(resp => {
        updateOpeningsTableParams({ count: resp.count, next: !!resp.next, prev: !!resp.previous });
        setOpenings(openingMap(resp));
      })
      .finally(() => {
        setTableOpeningsLoading(false);
      });
  };

  useEffect(() => {
    if (!tableOpeningsParams.offset && tableOpeningsParams.pageToShow === 1) getOpenings(appliedFilters);
    else updateOpeningsTableParams({ offset: 0, pageToShow: 1 });
  }, [tableOpeningsParams.offset, tableOpeningsParams.limit, tableOpeningsParams.sorting, appliedFilters]);

  return (
    <OpeningsTable
      filters={appliedFilters}
      loading={tableOpeningsLoading}
      openings={openings}
      options={tableOpeningsParams}
      itemsPerPage={ITEM_PER_PAGE}
      setOptions={updateOpeningsTableParams}
    />
  );
};
