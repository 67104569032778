import React, { useMemo } from 'react';
import { Tooltip, AtsSkeleton } from '_atoms';
import './transition-step.scss';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import { TOOLTIP_TXT } from '../../../constants/constants';
import { AnalyticsEmpty } from '../AnalyticsEmpty/analytics-empty';
import Empty from '../../../dist/images/common/icons/empty-analytics.jpg';

export const TransitionStep = ({ options, loading, role, empty, filter }) => {
  const transitionOptions = useMemo(() => {
    if (role !== 'recruiters' || filter.candidates.length)
      return options.filter(option => option.type !== 'Interacted');
    return options;
  }, [role, options]);

  const biggestNumber = useMemo(
    () => Math.max(...transitionOptions.map(item => item.candidates_count)),
    [transitionOptions]
  );

  return (
    <div className="transition-step shadow">
      {loading ? (
        <AtsSkeleton height={30} width={217} />
      ) : (
        <span className="title">Transition of candidates from step to step</span>
      )}
      {loading ? (
        <AtsSkeleton height={368} />
      ) : !empty ? (
        <div className="graphic">
          <ul>
            <li>Steps</li>
            {transitionOptions.map(option => (
              <li key={option.type}>{option.type}</li>
            ))}
          </ul>
          <ul>
            <li>Candidates</li>
            {transitionOptions.map((item, idx) => {
              const orange =
                biggestNumber === item.candidates_count ? '100' : `${(item.candidates_count * 100) / biggestNumber}`;
              const lightOrange = 100 - orange;
              return (
                <li key={`key_${idx + 1}`} className="transition-block">
                  <div className="number">
                    <span>{item.candidates_count}</span>
                  </div>
                  <div className="line-block">
                    <div className="line">
                      <div className="orange" style={{ width: `${orange}%` }} />
                      <div className="light-orange" style={{ width: `${lightOrange}%` }} />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <ul>
            <li>
              Absolute conversion
              <Tooltip white label={TOOLTIP_TXT.ABSOLUTE}>
                <Info />
              </Tooltip>
            </li>
            {transitionOptions.map((item, idx) => (
              <li key={`${idx + 1}`}>{item.absolute_conversion || '-'}</li>
            ))}
          </ul>
          <ul>
            <li>
              Relative conversion
              <Tooltip white label={TOOLTIP_TXT.RELATIVE}>
                <Info />
              </Tooltip>
            </li>
            {transitionOptions.map((item, idx) => (
              <li key={`${idx + 1}`}>{item.relative_conversion || '-'}</li>
            ))}
          </ul>
        </div>
      ) : (
        <AnalyticsEmpty text="There is no Transition of candidates from step to step" img={Empty} />
      )}
    </div>
  );
};
