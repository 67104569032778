import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'antd';
import cn from 'classnames';
import { Checkbox, Table, Tabs } from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { SelfSubmittedCandidatesDashboard } from '_organisms';
import useScreenResize from 'helpers/useScreenResize';
import { useQuery } from 'helpers/useLocation';
import { getRecruiterCandidatesTableDashboardAPI } from 'actions';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { CANDIDATES_TABLES_MAPPERS } from './utils';
import { INITIAL_VALUES, CANDIDATES_GROUP, CANDIDATES_TABLE_COLUMNS, TABS } from './constants';
import './style.scss';

export const DashboardRecruiter = () => {
  const query = useQuery();
  const [tab, setTab] = useState(query.tab || TABS[0].key);

  return (
    <div className="dashboard-recruiter">
      <Tabs className="dashboard-recruiter__tabs" activeTab={tab} onChange={setTab} items={TABS} />
      {
        {
          [TABS[0].key]: <MyActiveCandidates />,
          [TABS[1].key]: <SelfSubmittedCandidatesDashboard dashboard="recruiter" />,
        }[tab]
      }
    </div>
  );
};

const MyActiveCandidates = () => {
  const tableRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES);
  const [count, setCount] = useState(0);
  const [grouping, setGrouping] = useState(CANDIDATES_GROUP[0]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      setTableOptions(s => ({ ...s, page: item, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, limit: item, offset: 0, page: 1 }));
    } else if (field === 'only_with_actions') {
      setTableOptions(s => ({ ...s, only_with_actions: item, offset: 0, page: 1 }));
    } else {
      setTableOptions(s => ({ ...s, [field]: item }));
    }
  };
  const setSorting = sort => onChangeFilter('sorting', sort);
  const onChangeWithActions = e => onChangeFilter('only_with_actions', e.target.checked);

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onGroupingChange = newGrouping => {
    setGrouping(g => ({ ...newGrouping, tableData: g.tableData }));
  };

  const getTableData = () => {
    setLoading(true);
    getRecruiterCandidatesTableDashboardAPI(
      {
        limit: tableOptions.limit.value,
        offset: tableOptions.offset,
        sorting_field: tableOptions.sorting.field,
        sorting: tableOptions.sorting.order,
        only_with_actions: tableOptions.only_with_actions,
      },
      grouping.value
    )
      .then(d => {
        setTableData([]);
        setTableOptions(o => ({ ...o, itemsCount: d.count }));
        setCount(d.count);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableData(CANDIDATES_TABLES_MAPPERS[grouping.value](d.results));
        setExpandedRowKeys(d.results.map((_, index) => index));
      })
      .catch(() => {
        setTableData([]);
        setCount(0);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableOptions(INITIAL_VALUES);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useScreenResize('md', tableRef);

  useEffect(getTableData, [
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    tableOptions.only_with_actions,
    grouping.value,
  ]);

  return (
    <Table
      innerRef={tableRef}
      rowKey="uuid"
      className={cn('active-candidates', grouping.tableData)}
      cardTitle="Active candidates"
      cardCount={count}
      emptyComponent={<ActiveCandidatesTableEmpty />}
      extra={
        <div className="active-candidates__header-extra">
          <Checkbox
            label="Show only with action"
            isSelected={tableOptions.only_with_actions}
            onChange={onChangeWithActions}
          />
          <Divider type="vertical" />
          <div className="filter-container">
            <SelectSmall
              label="Group by"
              options={CANDIDATES_GROUP}
              value={grouping}
              onChange={onGroupingChange}
              placement={PLACEMENTS.BOTTOM_RIGHT}
            />
          </div>
        </div>
      }
      columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][0](tableOptions.sorting, setSorting)}
      loading={loading}
      data={tableData}
      limit={tableOptions.limit}
      page={tableOptions.page}
      count={tableData.reduce((s, d) => s + d.candidates?.length, 0) || tableData.length}
      offset={tableOptions.offset}
      itemsCount={tableOptions.itemsCount}
      tooltipInfo="One candidate may be mentioned more than once (for example, if assigned to more than one job)"
      updateParams={onChangeFilter}
      expandable={
        grouping.tableData && {
          expandedRowKeys,
          expandedRowRender: ({ candidates }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('active-candidates__sub-table', grouping.tableData)}
                columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1]}
                data={candidates}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('active-candidates__sub-table active-candidates__sub-table-mobile', grouping.tableData)}
                data={candidates}
                columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1]}
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name" onClick={() => {}}>
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.extra && <Typography.Text className="extra">{record.extra}</Typography.Text>}
              </div>
            </div>
          ),
        }
      }
      defaultTableMobile={!grouping.tableData}
    />
  );
};

const ActiveCandidatesTableEmpty = () => (
  <div className="active-candidates__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no active candidates here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);
