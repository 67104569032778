import { Ability, AbilityBuilder } from '@casl/ability';
import { ACTIONS, UI, ROLES, SUBJECTS } from './constants';
import { STEPS_LABELS } from '../constants/constants';
import { OPENING_STATUSES } from '../constants/opening';
import { JOB_STATUSES } from '../constants/job';
import { STATUSES } from '../constants/user';

export const ability = new Ability();

export const updateAbility = (passAbility, user) => {
  const { can, rules } = new AbilityBuilder(Ability);

  const role = user.active_role?.name;

  switch (role) {
    case ROLES.SUPER_ADMIN:
      can([ACTIONS.CREATE, ACTIONS.UPDATE, ACTIONS.DELETE], SUBJECTS.JOB);
      can([ACTIONS.CREATE, ACTIONS.UPDATE, ACTIONS.DELETE], SUBJECTS.OPENING);
      can(ACTIONS.READ, UI.CREATE_JOB_TEAM);
      can(ACTIONS.READ, UI.ASSIGN_CANDIDATE);
      can(ACTIONS.READ, UI.ASSIGN_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.UNASSIGN_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.UNASSIGN_RECRUITER_LEAD, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.EDIT_CANDIDATE_TIMELINE);
      can(ACTIONS.READ, UI.EDIT_RESPONSIBLE_RECRUITER);
      can(ACTIONS.CREATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.UPDATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.READ, UI.BLACKLIST_CANDIDATE, { status: { $ne: STATUSES.BLACKLISTED } });
      can(ACTIONS.READ, UI.FIRE_CANDIDATE);
      can(ACTIONS.DELETE, SUBJECTS.CANDIDATE);
      can(ACTIONS.READ, UI.ACTIONS_TL_SA);
      // can(ACTIONS.READ, UI.CLONE_JOB);
      can(ACTIONS.READ, UI.ARCHIVE_JOB);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.SUBMIT_FINAL_DECISION);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_WITHDRAW);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ADD_APPROVER);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ASSIGN_TO_STEP);
      can(ACTIONS.READ, UI.ANALYTICS_PAGE);
      can(ACTIONS.CREATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.UPDATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.DELETE, SUBJECTS.INTERVIEW);
      can(ACTIONS.READ, UI.APPROVE_DECLINE, {
        status: {
          $in: [
            OPENING_STATUSES.HRL_APPROVAL,
            OPENING_STATUSES.LM_APPROVAL,
            OPENING_STATUSES.RM_APPROVAL,
            OPENING_STATUSES.SH_APPROVAL,
          ],
        },
      });
      can(ACTIONS.READ, UI.SUBMIT_OFFER, { step: { $eq: STEPS_LABELS.OFFER } });
      can(ACTIONS.CRUD, SUBJECTS.REMINDER);
      can(ACTIONS.CRUD, SUBJECTS.INTERACTION);
      can(ACTIONS.READ, UI.EDIT_RECRUITER_REMINDER);
      can(ACTIONS.READ, UI.ANALYTICS_REPORTING_TAB);
      can(ACTIONS.READ, UI.ANALYTICS_SSC_TAB);
      can(ACTIONS.READ, UI.CHANGE_TEAM_LEAD);
      can(ACTIONS.READ, UI.ASSIGN_RECRUITER_LEAD);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_DISPLAY_SHOW_MORE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COUNTRY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CITY);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SPECIALTY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TAGS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COMPANIES);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_RESPONSIBLE_RECRUITER);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SOURCE);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SERVICE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_LAST_INTERACTION);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_DURING_PERIOD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_COMPANIES);
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_RECRUITER);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);
      can(ACTIONS.READ, UI.JOB_LIST_EXPERIENCE_LEVEL);

      can(ACTIONS.READ, UI.ONLY_INTERACTED);

      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);

      // job hired filters
      can(ACTIONS.READ, UI.JOB_HIRED_ACTION);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_SERVICE);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);
      can(ACTIONS.UPDATE, UI.JOB_TEAM_LEAD);
      can(ACTIONS.UPDATE, UI.JOB_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { rejected: { $eq: false } });

      // opening creation form
      can(ACTIONS.UPDATE, UI.OPENING_TEAM_LEAD);
      can(ACTIONS.UPDATE, UI.OPENING_PRIORITY);
      can(ACTIONS.UPDATE, UI.OPENING_EXPECTED_DATE);
      can(ACTIONS.UPDATE, UI.OPENING_OFFICE_LOCATIONS);
      can(ACTIONS.UPDATE, UI.OPENING_FOREIGN_LANGUAGES);
      can(ACTIONS.UPDATE, UI.OPENING_EMPLOYMENT_TYPE);
      can(ACTIONS.UPDATE, UI.OPENING_WORK_EXPERIENCE);
      can(ACTIONS.UPDATE, UI.OPENING_EXPERIENCE_LEVEL);
      can(ACTIONS.UPDATE, UI.OPENING_RESPONSIBILITIES);
      can(ACTIONS.UPDATE, UI.OPENING_COMMENT);

      can(ACTIONS.READ, UI.CLONE_OPENING);

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_ENGLISH_LEVEL);
      can(ACTIONS.UPDATE, UI.FEEDBACK_EXPERIENCE_LEVEL);

      // resolution
      can(ACTIONS.READ, UI.EDIT_RESOLUTION, { status: { $eq: JOB_STATUSES.OPEN } });

      // candidate profile
      can(ACTIONS.READ, UI.SHARE_PROFILE);
      break;
    case ROLES.HR_LEAD:
      can(ACTIONS.READ, UI.ASSIGN_CANDIDATE);
      can(ACTIONS.READ, UI.APPROVE_DECLINE, { status: { $eq: OPENING_STATUSES.HRL_APPROVAL } });
      can(ACTIONS.READ, UI.ASSIGN_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.UNASSIGN_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.UNASSIGN_RECRUITER_LEAD, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can([ACTIONS.UPDATE, ACTIONS.DELETE], SUBJECTS.JOB);
      can(ACTIONS.READ, UI.EDIT_CANDIDATE_TIMELINE);
      can(ACTIONS.READ, UI.EDIT_RESPONSIBLE_RECRUITER);
      can(ACTIONS.CREATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.UPDATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.READ, UI.BLACKLIST_CANDIDATE, { status: { $ne: STATUSES.BLACKLISTED } });
      can(ACTIONS.READ, UI.FIRE_CANDIDATE);
      can(ACTIONS.DELETE, SUBJECTS.CANDIDATE);
      can(ACTIONS.READ, UI.ANALYTICS_PAGE);
      can(ACTIONS.READ, UI.ARCHIVE_JOB);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.SUBMIT_FINAL_DECISION);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_WITHDRAW);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ADD_APPROVER);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ASSIGN_TO_STEP);
      can(ACTIONS.CREATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.UPDATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.DELETE, SUBJECTS.INTERVIEW);
      can(ACTIONS.READ, UI.SUBMIT_OFFER, { step: { $eq: STEPS_LABELS.OFFER } });
      can(ACTIONS.DELETE, SUBJECTS.OPENING);
      can(ACTIONS.UPDATE, SUBJECTS.OPENING, {
        status: { $nin: [OPENING_STATUSES.DECLINED, OPENING_STATUSES.CLOSED, OPENING_STATUSES.HIRED] },
      });
      can(ACTIONS.CRUD, SUBJECTS.REMINDER);
      can(ACTIONS.CRUD, SUBJECTS.INTERACTION);
      can(ACTIONS.READ, UI.EDIT_RECRUITER_REMINDER);
      can(ACTIONS.READ, UI.ANALYTICS_REPORTING_TAB);
      can(ACTIONS.READ, UI.ANALYTICS_SSC_TAB);
      can(ACTIONS.READ, UI.ASSIGN_RECRUITER_LEAD);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_DISPLAY_SHOW_MORE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COUNTRY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CITY);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SPECIALTY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TAGS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COMPANIES);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_RESPONSIBLE_RECRUITER);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SOURCE);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SERVICE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_LAST_INTERACTION);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_DURING_PERIOD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_COMPANIES);
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_RECRUITER);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);

      can(ACTIONS.READ, UI.ONLY_INTERACTED);

      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);

      // job hired filters
      can(ACTIONS.READ, UI.JOB_HIRED_ACTION);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_SERVICE);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);
      can(ACTIONS.UPDATE, UI.JOB_TEAM_LEAD);
      can(ACTIONS.UPDATE, UI.JOB_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { rejected: { $eq: false } });

      // opening creation form
      can(ACTIONS.UPDATE, UI.OPENING_PRIORITY);
      can(ACTIONS.UPDATE, UI.OPENING_EXPECTED_DATE);
      can(ACTIONS.UPDATE, UI.OPENING_OFFICE_LOCATIONS);
      can(ACTIONS.UPDATE, UI.OPENING_FOREIGN_LANGUAGES);
      can(ACTIONS.UPDATE, UI.OPENING_EMPLOYMENT_TYPE);
      can(ACTIONS.UPDATE, UI.OPENING_COMMENT);

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_ENGLISH_LEVEL);

      // resolution
      can(ACTIONS.READ, UI.EDIT_RESOLUTION, { status: { $eq: JOB_STATUSES.OPEN } });

      // candidate profile
      can(ACTIONS.READ, UI.SHARE_PROFILE);
      break;
    case ROLES.RECRUITER_LEAD:
      can(ACTIONS.READ, UI.ASSIGN_CANDIDATE);
      can(ACTIONS.READ, UI.EDIT_CANDIDATE_TIMELINE);
      can(ACTIONS.READ, UI.EDIT_RESPONSIBLE_RECRUITER);
      can(ACTIONS.CREATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.UPDATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.READ, UI.BLACKLIST_CANDIDATE, { status: { $ne: STATUSES.BLACKLISTED } });
      can(ACTIONS.READ, UI.FIRE_CANDIDATE);
      can(ACTIONS.DELETE, SUBJECTS.CANDIDATE);
      can(ACTIONS.UPDATE, SUBJECTS.OPENING, {
        status: { $nin: [OPENING_STATUSES.DECLINED, OPENING_STATUSES.CLOSED, OPENING_STATUSES.HIRED] },
      });
      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);
      can(ACTIONS.READ, UI.ARCHIVE_JOB);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.SUBMIT_FINAL_DECISION);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_WITHDRAW);
      can(ACTIONS.READ, UI.ASSIGN_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.UNASSIGN_RECRUITER, { status: { $in: [JOB_STATUSES.OPEN, JOB_STATUSES.APPROVED] } });
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ADD_APPROVER);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ASSIGN_TO_STEP);
      can(ACTIONS.READ, UI.ONLY_MU_CANDIDATES);
      can(ACTIONS.READ, UI.ANALYTICS_PAGE);
      can(ACTIONS.CREATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.UPDATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.DELETE, SUBJECTS.INTERVIEW);
      can(ACTIONS.READ, UI.SUBMIT_OFFER, { step: { $eq: STEPS_LABELS.OFFER } });
      can(ACTIONS.CRUD, SUBJECTS.REMINDER);
      can(ACTIONS.CRUD, SUBJECTS.INTERACTION);
      can(ACTIONS.READ, UI.ANALYTICS_REPORTING_TAB);
      can(ACTIONS.READ, UI.ANALYTICS_SSC_TAB);
      can(ACTIONS.READ, UI.EDIT_RECRUITER_REMINDER);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_DISPLAY_SHOW_MORE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COUNTRY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CITY);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SPECIALTY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TAGS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COMPANIES);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_RESPONSIBLE_RECRUITER);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SOURCE);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SERVICE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_LAST_INTERACTION);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_DURING_PERIOD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_COMPANIES);
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_RECRUITER);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);

      can(ACTIONS.READ, UI.ONLY_INTERACTED);

      // job hired filters
      can(ACTIONS.READ, UI.JOB_HIRED_ONLY_MY);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { rejected: { $eq: false } });

      // opening creation form
      can(ACTIONS.UPDATE, UI.OPENING_PRIORITY);
      can(ACTIONS.UPDATE, UI.OPENING_EXPECTED_DATE);
      can(ACTIONS.UPDATE, UI.OPENING_OFFICE_LOCATIONS);
      can(ACTIONS.UPDATE, UI.OPENING_FOREIGN_LANGUAGES);
      can(ACTIONS.UPDATE, UI.OPENING_EMPLOYMENT_TYPE);
      can(ACTIONS.UPDATE, UI.OPENING_COMMENT);

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_ENGLISH_LEVEL);

      // resolution
      can(ACTIONS.READ, UI.EDIT_RESOLUTION, { status: { $eq: JOB_STATUSES.OPEN } });

      // candidate profile
      can(ACTIONS.READ, UI.SHARE_PROFILE);
      break;
    case ROLES.RECRUITER:
      can(ACTIONS.READ, UI.ASSIGN_CANDIDATE);
      can(ACTIONS.READ, UI.EDIT_CANDIDATE_TIMELINE);
      can(ACTIONS.READ, UI.ASSIGN_CANDIDATE_TO_ME);
      can(ACTIONS.CREATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.UPDATE, SUBJECTS.CANDIDATE);
      can(ACTIONS.READ, UI.BLACKLIST_CANDIDATE, { status: { $ne: STATUSES.BLACKLISTED } });
      can(ACTIONS.READ, UI.FIRE_CANDIDATE);
      can(ACTIONS.DELETE, SUBJECTS.CANDIDATE);
      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.SUBMIT_FINAL_DECISION);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_WITHDRAW);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ADD_APPROVER);
      can(ACTIONS.READ, UI.ACTIONS_STEPS_ASSIGN_TO_STEP);
      can(ACTIONS.READ, UI.ONLY_MU_CANDIDATES);
      can(ACTIONS.CREATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.UPDATE, SUBJECTS.INTERVIEW);
      can(ACTIONS.DELETE, SUBJECTS.INTERVIEW);
      can(ACTIONS.READ, UI.SUBMIT_OFFER, { step: { $eq: STEPS_LABELS.OFFER } });
      can(ACTIONS.CRUD, SUBJECTS.REMINDER);
      can(ACTIONS.CRUD, SUBJECTS.INTERACTION);
      can(ACTIONS.READ, UI.EDIT_RECRUITER_REMINDER);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_DISPLAY_SHOW_MORE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COUNTRY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CITY);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SPECIALTY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TAGS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_COMPANIES);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_RESPONSIBLE_RECRUITER);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SOURCE);
      // can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_SERVICE);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_LAST_INTERACTION);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_ADDED_DURING_PERIOD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_BY);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_COMPANIES);
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_RECRUITER);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);

      can(ACTIONS.READ, UI.ONLY_INTERACTED);

      // job hired filters
      can(ACTIONS.READ, UI.JOB_HIRED_ONLY_MY);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { rejected: { $eq: false } });

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_ENGLISH_LEVEL);

      // resolution
      can(ACTIONS.READ, UI.EDIT_RESOLUTION, { status: { $eq: JOB_STATUSES.OPEN } });

      // candidate profile
      can(ACTIONS.READ, UI.SHARE_PROFILE);
      break;
    case ROLES.RESOURCE_MANAGER:
      can([ACTIONS.CREATE, ACTIONS.UPDATE, ACTIONS.DELETE], SUBJECTS.JOB);
      can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.OPENING);
      can(ACTIONS.UPDATE, SUBJECTS.OPENING, {
        status: { $nin: [OPENING_STATUSES.DECLINED, OPENING_STATUSES.CLOSED, OPENING_STATUSES.HIRED] },
      });
      can(ACTIONS.READ, UI.CREATE_JOB_TEAM);
      // can(ACTIONS.READ, UI.CLONE_JOB);
      can(ACTIONS.READ, UI.APPROVE_DECLINE, { status: { $eq: OPENING_STATUSES.RM_APPROVAL } });
      can(ACTIONS.READ, UI.ARCHIVE_JOB);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.ANALYTICS_PAGE);
      can(ACTIONS.READ, UI.CHANGE_TEAM_LEAD);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_COMPANIES);
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_RECRUITER);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);

      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_SERVICE);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);
      can(ACTIONS.UPDATE, UI.JOB_TEAM_LEAD);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { is_default: { $eq: true }, rejected: { $eq: false } });

      // opening creation form
      can(ACTIONS.UPDATE, UI.OPENING_TEAM_LEAD);
      can(ACTIONS.UPDATE, UI.OPENING_PRIORITY);
      can(ACTIONS.UPDATE, UI.OPENING_EXPECTED_DATE);
      can(ACTIONS.UPDATE, UI.OPENING_OFFICE_LOCATIONS);
      can(ACTIONS.UPDATE, UI.OPENING_FOREIGN_LANGUAGES);
      can(ACTIONS.UPDATE, UI.OPENING_EMPLOYMENT_TYPE);
      can(ACTIONS.UPDATE, UI.OPENING_WORK_EXPERIENCE);
      can(ACTIONS.UPDATE, UI.OPENING_EXPERIENCE_LEVEL);
      can(ACTIONS.UPDATE, UI.OPENING_RESPONSIBILITIES);
      can(ACTIONS.UPDATE, UI.OPENING_COMMENT);

      can(ACTIONS.READ, UI.CLONE_OPENING);

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_EXPERIENCE_LEVEL);
      break;
    case ROLES.TEAM_LEAD:
      can([ACTIONS.CREATE, ACTIONS.UPDATE], SUBJECTS.JOB);
      can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.OPENING);
      can(ACTIONS.UPDATE, SUBJECTS.OPENING, {
        status: { $nin: [OPENING_STATUSES.DECLINED, OPENING_STATUSES.CLOSED, OPENING_STATUSES.HIRED] },
      });
      can(ACTIONS.READ, UI.CREATE_JOB_TEAM);
      can(ACTIONS.READ, UI.ACTIONS_TL_SA);
      // can(ACTIONS.READ, UI.CLONE_JOB);
      can(ACTIONS.READ, UI.ARCHIVE_JOB);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.ONLY_MY_OPENINGS);
      can(ACTIONS.READ, UI.CHANGE_TEAM_LEAD);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);
      can(ACTIONS.READ, UI.JOB_LIST_EXPERIENCE_LEVEL);

      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);

      // job hired filters
      can(ACTIONS.READ, UI.JOB_HIRED_ONLY_MY);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_SERVICE);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);
      can(ACTIONS.UPDATE, UI.JOB_TEAM_LEAD);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { is_default: { $eq: true }, rejected: { $eq: false } });

      // opening creation form
      can(ACTIONS.UPDATE, UI.OPENING_TEAM_LEAD);
      can(ACTIONS.UPDATE, UI.OPENING_PRIORITY);
      can(ACTIONS.UPDATE, UI.OPENING_EXPECTED_DATE);
      can(ACTIONS.UPDATE, UI.OPENING_OFFICE_LOCATIONS);
      can(ACTIONS.UPDATE, UI.OPENING_FOREIGN_LANGUAGES);
      can(ACTIONS.UPDATE, UI.OPENING_EMPLOYMENT_TYPE);
      can(ACTIONS.UPDATE, UI.OPENING_WORK_EXPERIENCE);
      can(ACTIONS.UPDATE, UI.OPENING_EXPERIENCE_LEVEL);
      can(ACTIONS.UPDATE, UI.OPENING_RESPONSIBILITIES);
      can(ACTIONS.UPDATE, UI.OPENING_COMMENT);

      can(ACTIONS.READ, UI.CLONE_OPENING);

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_EXPERIENCE_LEVEL);
      break;
    case ROLES.SERVICE_HEAD:
      can([ACTIONS.CREATE, ACTIONS.UPDATE], SUBJECTS.JOB);
      can([ACTIONS.CREATE, ACTIONS.DELETE], SUBJECTS.OPENING);
      can(ACTIONS.UPDATE, SUBJECTS.OPENING, {
        status: { $nin: [OPENING_STATUSES.DECLINED, OPENING_STATUSES.CLOSED, OPENING_STATUSES.HIRED] },
      });
      can(ACTIONS.READ, UI.CREATE_JOB_TEAM);
      can(ACTIONS.READ, UI.ACTIONS_TL_SA);
      // can(ACTIONS.READ, UI.CLONE_JOB);
      can(ACTIONS.READ, UI.ARCHIVE_JOB);
      can(ACTIONS.READ, UI.ONLY_MY_OPENINGS);
      can(ACTIONS.READ, UI.APPROVE_DECLINE, {
        status: { $in: [OPENING_STATUSES.SH_APPROVAL] },
      });
      can(ACTIONS.READ, UI.DEFAULT_SERVICE);
      can(ACTIONS.READ, UI.ANALYTICS_PAGE);
      can(ACTIONS.READ, UI.SUBMIT_DECISION);
      can(ACTIONS.READ, UI.SUBMIT_FINAL_DECISION, {
        step: { $in: [2, 3] },
      });
      can(ACTIONS.READ, UI.CHANGE_TEAM_LEAD);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);
      can(ACTIONS.READ, UI.JOB_LIST_EXPERIENCE_LEVEL);

      // edit job
      can(ACTIONS.UPDATE, SUBJECTS.JOB);

      // job hired filters
      can(ACTIONS.READ, UI.JOB_HIRED_ONLY_MY);

      // job creation form
      can(ACTIONS.UPDATE, UI.JOB_INFO_NAME);
      can(ACTIONS.UPDATE, UI.JOB_SERVICE);
      can(ACTIONS.UPDATE, UI.JOB_DESCRIPTION);
      can(ACTIONS.UPDATE, UI.JOB_BENEFITS);
      can(ACTIONS.UPDATE, UI.JOB_TEAM_LEAD);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { is_default: { $eq: true }, rejected: { $eq: false } });

      // opening creation form
      can(ACTIONS.UPDATE, UI.OPENING_TEAM_LEAD);
      can(ACTIONS.UPDATE, UI.OPENING_PRIORITY);
      can(ACTIONS.UPDATE, UI.OPENING_EXPECTED_DATE);
      can(ACTIONS.UPDATE, UI.OPENING_OFFICE_LOCATIONS);
      can(ACTIONS.UPDATE, UI.OPENING_FOREIGN_LANGUAGES);
      can(ACTIONS.UPDATE, UI.OPENING_EMPLOYMENT_TYPE);
      can(ACTIONS.UPDATE, UI.OPENING_WORK_EXPERIENCE);
      can(ACTIONS.UPDATE, UI.OPENING_EXPERIENCE_LEVEL);
      can(ACTIONS.UPDATE, UI.OPENING_RESPONSIBILITIES);
      can(ACTIONS.UPDATE, UI.OPENING_COMMENT);

      can(ACTIONS.READ, UI.CLONE_OPENING);

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_EXPERIENCE_LEVEL);
      break;
    case ROLES.LOCATION_MANAGER:
      can(ACTIONS.READ, UI.LOCATION_MANAGER_PERMISSIONS);
      can(ACTIONS.READ, UI.APPROVE_DECLINE, {
        status: { $in: [OPENING_STATUSES.LM_APPROVAL] },
        isApprover: { $eq: true },
      });

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_COMPANIES);
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_RECRUITER);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { is_default: { $eq: true }, rejected: { $eq: false } });

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_EXPERIENCE_LEVEL);
      break;
    case ROLES.INTERVIEWER:
      can(ACTIONS.READ, UI.SUBMIT_DECISION);

      // candidate list filters
      can(ACTIONS.READ, UI.CANDIDATE_LIST_CANDIDATE_STATUS);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_EXPERIENCE_LEVEL);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_JOB_STEP);
      can(ACTIONS.READ, UI.CANDIDATE_LIST_INTERVIEWED_DATE);

      // job list filters
      can(ACTIONS.READ, UI.JOB_LIST_SERVICE);
      can(ACTIONS.READ, UI.JOB_LIST_TEAM_LEAD);
      can(ACTIONS.READ, UI.JOB_LIST_JOB_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OPENING_STATUS);
      can(ACTIONS.READ, UI.JOB_LIST_OFFICE);
      can(ACTIONS.READ, UI.JOB_LIST_EXPERIENCE_LEVEL);

      // job approvers
      can(ACTIONS.READ, UI.EDIT_APPROVERS, { is_default: { $eq: true }, rejected: { $eq: false } });

      // feedback creation form
      can(ACTIONS.UPDATE, UI.FEEDBACK_EXPERIENCE_LEVEL);

      can(ACTIONS.READ, UI.APPROVE_DECLINE, {
        status: { $in: [OPENING_STATUSES.LM_APPROVAL] },
      });
      break;
  }
  passAbility.update(rules);
};
