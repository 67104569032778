import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import cn from 'classnames';
import { RichText, Tooltip } from '_atoms';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import './style.scss';

const Table = ({ sorting, header, data, onChangeSort, minCellWidth = 168, maxCellWidth = 264 }) => {
  const [columns, setColumns] = useState(header.map(({ width }) => width));
  const resizers = useMemo(
    () => columns.map((_, colNumber) => columns.slice(0, colNumber + 1).reduce((prev, curr) => prev + curr, -4)),
    [columns]
  );
  const [tableScrollRight, setTableScrollRight] = useState(0);
  const [resizer, setResizer] = useState(null);
  const tableElement = useRef();

  const scroll = () => setTableScrollRight(tableElement.current.scrollLeft);
  const clearResizeRef = useCallback(() => setResizer(null), []);
  const mouseMove = e => {
    setColumns(c =>
      c.map((col, i) =>
        i === resizer && col + e.movementX >= minCellWidth && col + e.movementX <= maxCellWidth
          ? col + e.movementX
          : col
      )
    );
  };

  useEffect(() => {
    if (tableElement && tableElement.current) tableElement.current?.addEventListener('scroll', scroll);
    return () => {
      tableElement.current?.removeEventListener('scroll', scroll);
    };
  }, []);

  useEffect(() => {
    if (resizer !== null) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', clearResizeRef);
    } else {
      window.removeEventListener('mousemove', mouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('mouseup', clearResizeRef);
    };
  }, [resizer]);

  return (
    <div
      ref={tableElement}
      className={cn('table-wrapper', { disable_selection: resizer !== null })}
      style={{
        gridTemplateColumns: columns.map(w => `${w}px`).join(' '),
      }}
    >
      {header.map((head, colNumber) => (
        <React.Fragment key={head.dataKey}>
          <div
            key={head.dataKey}
            className={cn('table-column', { 'table-column_sticky': head.sticky && tableScrollRight })}
            style={{
              width: columns[colNumber],
              left: columns.slice(0, colNumber).reduce((prev, width) => (prev + head.sticky ? width : 0), 0),
            }}
          >
            <div className="table-column__header">
              {head.label}
              {head.sortable && (
                <Sort
                  onClick={() => onChangeSort({ field: head.label, key: head.dataKey })}
                  className={cn(
                    'table-column__filter_arrows',
                    { desc: sorting?.find(s => s.key === head.dataKey && s.order === 'desc') },
                    { asc: sorting?.find(s => s.key === head.dataKey && s.order === 'asc') }
                  )}
                />
              )}
            </div>
            {data.map((cell, idx) => (
              <Cell cell={cell} dataKey={head.dataKey} key={`${head.dataKey + idx}`} />
            ))}
          </div>
          <div
            role="none"
            className="table-wrapper__resizer"
            onMouseDown={() => setResizer(colNumber)}
            style={{
              height: tableElement.current?.scrollHeight ?? 0,
              left: resizers[colNumber] + (head.sticky ? tableScrollRight : 0),
              zIndex: head.sticky ? 3 : 1,
            }}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const Cell = React.memo(
  ({ cell, dataKey }) =>
    cell[dataKey].data ? (
      <div className={cn('table-column__data', cell[dataKey]?.className)}>
        <Tooltip label={<RichText text={cell[dataKey].data} opened />}>
          <div>{cell[dataKey].reachText ? <RichText text={cell[dataKey].data} /> : cell[dataKey].data}</div>
        </Tooltip>
      </div>
    ) : (
      <div className={cn('table-column__data', cell[dataKey]?.className)}>
        <div>{cell[dataKey].data ?? '—'}</div>
      </div>
    ),
  (prev, next) => prev.keyId === next.keyId
);
export default Table;
