import React from 'react';
import { Tooltip } from '_atoms';
import moment from 'moment';
import { ROW_NAMES } from './constants';

export const dataMapper = ({ analytics_total, analytics_by_month }) =>
  ROW_NAMES.map(({ name, key, totalKey }, idx) => ({
    action: name,
    total: ![0, 1, 7].includes(idx)
      ? {
          value: analytics_total[totalKey] || '-',
          tooltip: analytics_total[totalKey]
            ? `Total ${name} / Total ${ROW_NAMES[0].name} = (${analytics_total[totalKey]}/${analytics_total.total_created_by_system})*100%`
            : '-',
          percentage: analytics_total[totalKey]
            ? ` (${
                analytics_total.total_created_by_system
                  ? Math.round((analytics_total[totalKey] / analytics_total.total_created_by_system) * 1000) / 10
                  : 0
              }%)`
            : '',
        }
      : analytics_total[totalKey],
    ...analytics_by_month.reduce((acc, data) => {
      acc[data.month + data.year] =
        ![0, 1, 7].includes(idx) && data[key]
          ? {
              value: data[key],
              tooltip: `${moment(data.month, 'M').format('MMMM')} ${name} / ${moment(data.month, 'M').format('MMMM')} ${
                ROW_NAMES[0].name
              } = (${data[key]}/${data.month_created_by_system})*100%`,
              percentage: ` (${
                data.month_created_by_system ? Math.round((data[key] / data.month_created_by_system) * 1000) / 10 : 0
              }%)`,
            }
          : data[key] || '-';
      return acc;
    }, {}),
  }));

export const columnsMapper = ({ analytics_by_month }) => [
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    width: 232,
  },
  {
    title: `Total (${analytics_by_month.length} month)`,
    key: 'total',
    dataIndex: 'total',
    width: 200,
    align: 'right',
    render: data =>
      typeof data === 'object' ? (
        <Tooltip label={data.tooltip}>
          {data.value}
          {data.percentage}
        </Tooltip>
      ) : (
        data
      ),
  },
  ...analytics_by_month.map(el => ({
    title: moment(el.month, 'M').format('MMMM'),
    key: el.month + el.year,
    dataIndex: el.month + el.year,
    width: 200,
    align: 'right',
    render: data =>
      typeof data === 'object' ? (
        <Tooltip label={data.tooltip}>
          {data.value}
          {data.percentage}
        </Tooltip>
      ) : (
        data
      ),
  })),
];
