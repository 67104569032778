import React from 'react';
import { Table as TableAntd } from 'antd';
import cn from 'classnames';
import { Card } from '_atoms';
import { TableFooter, TableHeader } from '_molecules';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';
import './style.scss';

export const Table = ({
  columns,
  data,
  className,
  loading,
  showCardHeader = true,
  showCardFooter = true,
  emptyComponent = null,
  ...props
}) => {
  const { cardTitle, subtitle, tooltipInfo, limit, page, count, cardCount, offset, itemsCount, updateParams } = props;

  const updatePage = value => updateParams('page', value);
  const updateLimit = value => updateParams('limit', value);
  return (
    <Card className={cn('table-default-antd', className)}>
      {!!showCardHeader && (
        <TableHeader
          title={cardTitle}
          subtitle={subtitle}
          count={cardCount}
          tooltipInfo={tooltipInfo}
          loading={loading}
          sorting={props.timeSorting}
          onSorting={props.onTimeSorting}
          extra={props.extra}
          newLoading
        />
      )}
      <TableAntd
        ref={props.innerRef}
        className="table-default-antd__content"
        dataSource={data}
        columns={columns}
        pagination={false}
        showSorterTooltip={false}
        sortDirections={['ascend', 'descend', 'ascend']}
        loading={
          loading && {
            indicator: <CircularProgress />,
            spinning: true,
          }
        }
        tableLayout="fixed"
        locale={{
          emptyText: emptyComponent,
        }}
        {...props}
      />
      {props.defaultTableMobile && (
        <TableMobile data={data} columns={columns} empty={emptyComponent} loading={loading} />
      )}
      {!!showCardFooter && (
        <TableFooter
          count={count}
          limit={limit}
          page={page}
          onChangePage={updatePage}
          onChangeLimit={updateLimit}
          offset={offset}
          itemsCount={itemsCount}
          loading={loading}
          newLoading
        />
      )}
    </Card>
  );
};

export const TableMobile = ({ className, data, columns, empty = null, loading }) =>
  data.length ? (
    <div className={cn('table-mobile-default__content', className)}>
      {data.map(d => (
        <div key={d.uuid} className="item">
          {columns.map(c => (
            <div key={c.rowKey || c.key} className="item__row">
              {!c.hideMobileTitle && (
                <div className="title">{typeof c.title === 'function' ? c.title(true) : c.title}</div>
              )}
              <div className={cn('data', c.className, { extra: c.hideMobileTitle })}>
                {c.render?.(d[c.rowKey || c.key], d) ?? d[c.rowKey || c.key]}
              </div>
            </div>
          ))}
        </div>
      ))}
      {loading && (
        <div className="loading-overflow">
          <CircularProgress />
        </div>
      )}
    </div>
  ) : (
    <div className={cn('table-mobile-default__content', className)}>
      {empty}
      {loading && (
        <div className="loading-overflow">
          <CircularProgress />
        </div>
      )}
    </div>
  );
