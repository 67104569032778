import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import cn from 'classnames';
import { Card, Input, Select, Button, BUTTON_TYPES } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { ACTIONS, Can, UI } from 'permission';
import { fetchWrapper } from 'helpers/helpers';
import { useDebounceEffect } from 'helpers/useDebounceEffect';
import { BUTTON_TEXT } from 'constants/text';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import { LABELS, OPTIONS_REQUEST_CONFIG, FIELDS_HIERARCHY } from './constants';
import { optionsMappers, searchFiltersMapper } from './utils';
import './style.scss';

const FiltersJob = ({ form, initialValues, onFinish, onReset, loading }) => {
  const [filtersLoading, setFiltersLoading] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [openDetails, onOpenDetails] = useState(false);
  const onSearchDebounce = (type, search = '') => {
    if (filtersLoading[type]) return;
    setFiltersLoading(f => ({ ...f, [type]: true }));
    fetchWrapper(OPTIONS_REQUEST_CONFIG[type](search, searchFiltersMapper(form.getFieldsValue())))
      .then(options => {
        setFilterOptions(o => ({ ...o, [type]: optionsMappers[type](options) }));
        setFiltersLoading(f => ({ ...f, [type]: false }));
      })
      .catch(() => {
        setFilterOptions(o => ({ ...o, [type]: [] }));
        setFiltersLoading(f => ({ ...f, [type]: false }));
      });
  };

  const onSearch = useDebounceEffect(onSearchDebounce, 300);

  const typeToSearch = () => onFinish(form.getFieldsValue());

  const onValuesChange = values => {
    const field = Object.keys(values)[0];
    form.setFields(FIELDS_HIERARCHY[field]);
  };

  return (
    <Card className={cn('jobs-filters', { open: openDetails })}>
      <div className="jobs-filters__header">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Filters</Typography.Title2>
        <Button
          className={cn('open-details original-svg', { open: openDetails })}
          onClick={() => onOpenDetails(o => !o)}
          type={BUTTON_TYPES.GHOST}
          icon={<Arrow />}
        />
      </div>
      <div className="jobs-filters__body">
        <Form form={form} onFinish={onFinish} initialValues={initialValues} onValuesChange={onValuesChange}>
          <Input
            placeholder="Search by job name"
            type="search"
            onDebounceChange={loading ? undefined : typeToSearch}
            itemProps={{ name: 'search' }}
          />
          <Row gutter={[32, 0]}>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_COMPANIES} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.COMPANIES}
                    options={filterOptions.companies}
                    loading={filtersLoading.companies}
                    onSearch={v => onSearch('companies', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('companies')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'companies' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_SERVICE} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.SERVICE}
                    options={filterOptions.services}
                    loading={filtersLoading.services}
                    onSearch={v => onSearch('services', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('services')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'services' }}
                  />
                </Col>
              )}
            </Can>
          </Row>
          <Row gutter={[32, 0]}>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_TEAM_LEAD} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.TEAM_LEAD}
                    options={filterOptions.team_leads}
                    loading={filtersLoading.team_leads}
                    onSearch={v => onSearch('team_leads', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('team_leads')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'team_leads' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_JOB_STATUS} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.JOB_STATUS}
                    options={filterOptions.jobs_statuses}
                    loading={filtersLoading.jobs_statuses}
                    onSearch={v => onSearch('jobs_statuses', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('jobs_statuses')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'jobs_statuses' }}
                  />
                </Col>
              )}
            </Can>
          </Row>
          <Row gutter={[32, 0]}>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_RECRUITER} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.RECRUITER}
                    options={filterOptions.recruiters}
                    loading={filtersLoading.recruiters}
                    onSearch={v => onSearch('recruiters', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('recruiters')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'recruiters' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_OPENING_STATUS} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.OPENING_STATUS}
                    options={filterOptions.openings_statuses}
                    loading={filtersLoading.openings_statuses}
                    onSearch={v => onSearch('openings_statuses', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('openings_statuses')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'openings_statuses' }}
                  />
                </Col>
              )}
            </Can>
          </Row>
          <Row gutter={[32, 0]}>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_EXPERIENCE_LEVEL} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.EXPERIENCE_LEVEL}
                    options={filterOptions.experience_levels}
                    loading={filtersLoading.experience_levels}
                    onSearch={v => onSearch('experience_levels', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('experience_levels')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'experience_levels' }}
                  />
                </Col>
              )}
            </Can>
            <Can I={ACTIONS.READ} a={UI.JOB_LIST_OFFICE} passThrough>
              {access => (
                <Col span={24} lg={24} md={12} sm={24} className={cn({ hidden_field: !access })}>
                  <Select
                    labelInValue
                    label={LABELS.OFFICE}
                    options={filterOptions.offices}
                    loading={filtersLoading.offices}
                    onSearch={v => onSearch('offices', v)}
                    onDropdownVisibleChange={open => open && onSearchDebounce('offices')}
                    mode={SELECT_TYPES.MULTIPLE}
                    filterOption={false}
                    itemProps={{ name: 'offices' }}
                  />
                </Col>
              )}
            </Can>
          </Row>
          <div className="jobs-filters__footer">
            <Button type={BUTTON_TYPES.SECONDARY} onClick={loading ? undefined : onReset}>
              {BUTTON_TEXT.RESET_FILTERS}
            </Button>
            <Button type={BUTTON_TYPES.PRIMARY} htmlType={loading ? 'button' : 'submit'}>
              {BUTTON_TEXT.APPLY}
            </Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default FiltersJob;
