import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Input, AtsSkeleton } from '_atoms';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { Typography } from '_atoms/Typography';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { ITEM_PER_PAGE } from './constants';
import './style.scss';

const TableFooter = ({ limit, onChangeLimit, itemsCount, page, onChangePage, offset, count, loading, newLoading }) => {
  const pagesCount = Math.ceil(itemsCount / limit.value) || 1;
  const prev = page !== 1;
  const next = page !== pagesCount;
  const [inputPage, setInputPage] = useState(page);
  const onChangeInputPage = e => setInputPage(+e.target.value.replace(/\D/g, ''));
  const onDebounceChangeInputPage = newPage => {
    const value = +newPage.toString().replace(/\D/g, '');
    if (value <= 0) {
      setInputPage(1);
      if (page === 1) return;
      onChangePage(1);
    } else if (value > pagesCount) {
      setInputPage(pagesCount);
      if (page === pagesCount) return;
      onChangePage(pagesCount);
    } else {
      if (page === value) return;
      onChangePage(value);
    }
  };

  useEffect(() => {
    if (inputPage !== page) setInputPage(page);
  }, [page]);

  if (loading && !newLoading) {
    return (
      <div className="table-default__footer table-default__footer_loading">
        <AtsSkeleton height={28} />
      </div>
    );
  }

  return (
    <div className={cn('table-default__footer', { loading })}>
      <div className="item-per-page">
        <SelectSmall
          label="Items per page"
          options={ITEM_PER_PAGE}
          value={limit}
          onChange={onChangeLimit}
          placement={PLACEMENTS.TOP_LEFT}
        />
      </div>
      {!!itemsCount && <Typography.Text>{`${offset + 1}-${count + offset} of ${itemsCount} items`}</Typography.Text>}
      <div className="pagination">
        <Input
          value={inputPage || undefined}
          itemProps={{
            onChange: onChangeInputPage,
          }}
          onDebounceChange={e => onDebounceChangeInputPage(e.target.value)}
          delay={1000}
        />
        <Typography.Text>of {pagesCount} pages</Typography.Text>
        <div className="pagination__buttons">
          <Vector
            onClick={prev ? () => onDebounceChangeInputPage(page - 1) : null}
            className="vector"
            disabled={!prev}
          />
          <Vector
            onClick={next ? () => onDebounceChangeInputPage(page + 1) : null}
            className="vector rotate"
            disabled={!next}
          />
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
