import React, { useContext, useEffect, useState } from 'react';
import { Card, Table } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { TableHeader } from '_molecules';
import { ReactComponent as EmptyImage } from 'dist/images/common/icons/changelog.svg';
import { ReactComponent as CircularProgress } from 'dist/icons/loading.svg';
import { analyticWidgets } from 'actions/analyticActions';
import { columnsMapper, dataMapper } from './utils';
import { ROW_NAMES } from './constants';
import './style.scss';

export const AnalyticsSSCInfo = () => {
  const { appliedFilters } = useContext(Context);

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  const onApplyAnalytics = () => {
    setLoading(true);
    analyticWidgets('self-submitted-candidates-statistics', appliedFilters)
      .then(resp => {
        if (ROW_NAMES.reduce((total, { totalKey }) => total + resp.analytics_total[totalKey], 0)) {
          setColumns(columnsMapper(resp));
          setData(dataMapper(resp));
        } else {
          setColumns([]);
          setData([]);
        }
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(onApplyAnalytics, [appliedFilters]);

  return (
    <Card className="ssc-statistics">
      <TableHeader title="Self-submitted сandidates statistics" />
      {columns.length ? (
        <Table
          loading={loading}
          rowKey="id"
          columns={columns}
          data={data}
          showCardHeader={false}
          showCardFooter={false}
        />
      ) : (
        <EmptyTable loading={loading} />
      )}
    </Card>
  );
};

const EmptyTable = ({ loading }) => (
  <div className="empty-table">
    <EmptyImage className="empty-table__image" />
    <Typography.Title2 className="empty-table__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
      No data found
    </Typography.Title2>
    <Typography.Title3 className="empty-table__subtitle" weight={TYPOGRAPHY_WEIGHT.BOLD}>
      Please try another search criteria
    </Typography.Title3>
    {loading && (
      <div className="empty-table__loading">
        <CircularProgress width={40} height={40} />
      </div>
    )}
  </div>
);
