import React from 'react';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms';
import { ReactComponent as NoCandidates } from 'dist/emptyStates/stepsNoCandidates.svg';

export const Empty = ({ hasSearch }) => (
  <div className="self-submitted-candidates__empty">
    <NoCandidates />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>
      {hasSearch ? 'No candidates found' : 'You have no self-submitted Candidates for Review'}
    </Typography.Title3>
    {hasSearch && <Typography.Text>Please try different search criteria</Typography.Text>}
  </div>
);
