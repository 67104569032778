import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { AnalyticsTable } from '_templates/AnalyticsDashboard/AnalyticsTable/analytics-table';
import { Context } from '_templates/AnalyticsDashboard/analytics-dashboard';
import { CANDIDATES_TABLE_OPTIONS } from '_templates/AnalyticsDashboard/constans';
import { analyticWidgets } from 'actions/analyticActions';
import { candidateMap } from '_templates/utils';

export const AnalyticsCandidateInfo = () => {
  const { appliedFilters } = useContext(Context);
  const [candidates, setCandidates] = useState([]);
  const [tableCandidatesLoading, setTableCandidatesLoading] = useState(false);
  const [tableCandidatesParams, setTableCandidatesParams] = useState(CANDIDATES_TABLE_OPTIONS);

  const filterData = useMemo(
    () => ({
      ...appliedFilters,
      sorting: [{ field: tableCandidatesParams.sorting.field, order: tableCandidatesParams.sorting.order }],
    }),
    [appliedFilters, tableCandidatesParams.sorting]
  );

  const updateCandidatesTableParams = useCallback(
    params => setTableCandidatesParams(p => ({ ...p, ...params })),
    [setTableCandidatesParams]
  );

  const getCandidates = filterOptions => {
    setTableCandidatesLoading(true);
    analyticWidgets(
      'candidates',
      {
        ...filterOptions,
        sorting: [{ field: tableCandidatesParams.sorting.field, order: tableCandidatesParams.sorting.order }],
      },
      null,
      `?limit=${tableCandidatesParams.limit}&offset=${tableCandidatesParams.offset}`
    )
      .then(resp => {
        updateCandidatesTableParams({ count: resp.count, next: !!resp.next, prev: !!resp.previous });
        setCandidates(candidateMap(resp));
      })
      .finally(() => {
        setTableCandidatesLoading(false);
      });
  };

  useEffect(() => {
    if (!tableCandidatesParams.offset && tableCandidatesParams.pageToShow === 1) getCandidates(filterData);
    else updateCandidatesTableParams({ offset: 0, pageToShow: 1 });
  }, [tableCandidatesParams.offset, tableCandidatesParams.limit, tableCandidatesParams.sorting, filterData]);

  return (
    <AnalyticsTable
      filters={filterData}
      candidates={candidates}
      loading={tableCandidatesLoading}
      options={tableCandidatesParams}
      setOptions={updateCandidatesTableParams}
    />
  );
};
