import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Col, Form, Row } from 'antd';
import { Card, RangePicker, Select } from '_atoms';
import { FooterButton } from '_molecules';
import { useDebounceEffect } from 'helpers/useDebounceEffect';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { analyticsFilter } from 'actions/analyticActions';
import { ROUTES } from 'router/constants';
import {
  companiesOptions,
  serviceOptions,
  jobsOptions,
  candidatesOptions,
  teamLeadsOptions,
  recruitersOptions,
  openingsOptions,
  officesOptions,
} from './constants';
import './analytics-filter.scss';

export const AnalyticsFilter = ({ form, initialFilterOptions, onApply, onReset }) => {
  const { pathname: tab } = useLocation();

  const [company, setCompany] = useState({
    ...companiesOptions,
    options: initialFilterOptions.company,
    selected: initialFilterOptions.company,
  });
  const [service, setService] = useState({
    ...serviceOptions,
    options: initialFilterOptions.service,
    selected: initialFilterOptions.service,
  });
  const [job, setJob] = useState(jobsOptions);
  const [candidate, setCandidate] = useState(candidatesOptions);
  const [teamLead, setTeamLead] = useState(teamLeadsOptions);
  const [recruiter, setRecruiter] = useState(recruitersOptions);
  const [openingStatuses, setOpeningStatuses] = useState(openingsOptions);
  const [offices, setOffices] = useState(officesOptions);
  const [loadingField, setLoadingField] = useState('');

  const selectorsOptions = React.useMemo(() => {
    if (tab === ROUTES.ANALYTICS_RECRUITERS) return [company, service, job, recruiter, candidate];
    if (tab === ROUTES.ANALYTICS_TEAM_LEADS) return [company, service, teamLead, job];
    if (tab === ROUTES.ANALYTICS_CANDIDATE) return [service, job, recruiter, candidate];
    if (tab === ROUTES.ANALYTICS_REPORTING) return [company, service, job, recruiter];
    if (tab === ROUTES.ANALYTICS_OPENINGS) return [company, service, job, offices, openingStatuses];
    if (tab === ROUTES.ANALYTICS_SSC) return [];
    return [company, service, job, candidate];
  }, [tab, company, service, job, candidate, teamLead, recruiter, openingStatuses, offices]);

  const getOptions = (url, search = '') => {
    setLoadingField(url);
    const filters = form.getFieldsValue();
    const { search: _search, ...initFilters } = initialFilterOptions;
    const params = [...Object.entries(initFilters), ...Object.entries(filters)].reduce(
      (s, [key, value]) => ({ ...s, [key]: value }),
      {}
    );

    analyticsFilter(url.replace('_', '-'), params, search, url === openingStatuses.select)
      .then(data => {
        if (url === companiesOptions.select) setCompany(c => ({ ...c, options: data }));
        else if (url === serviceOptions.select) setService(s => ({ ...s, options: data }));
        else if (url === jobsOptions.select) setJob(j => ({ ...j, options: data }));
        else if (url === candidatesOptions.select) setCandidate(c => ({ ...c, options: data }));
        else if (url === recruitersOptions.select) setRecruiter(r => ({ ...r, options: data }));
        else if (url === teamLeadsOptions.select) setTeamLead(tl => ({ ...tl, options: data }));
        else if (url === openingStatuses.select) {
          setOpeningStatuses(os => ({ ...os, options: data.map(d => ({ value: d, label: d, id: d })) }));
        } else if (url === officesOptions.select) {
          setOffices(o => ({ ...o, options: data }));
        }
      })
      .finally(() => setLoadingField(''));
  };

  const onGetOptions = useDebounceEffect(getOptions, 300);

  const filtersHierarchyObj = {
    period: [],
    companies: ['services', 'team_leads', 'jobs', 'offices', 'recruiters', 'openings_statuses', 'candidates'],
    services: ['team_leads', 'jobs', 'offices', 'recruiters', 'openings_statuses', 'candidates'],
    team_leads: ['jobs', 'offices', 'recruiters', 'openings_statuses', 'candidates'],
    jobs: ['offices', 'recruiters', 'openings_statuses', 'candidates'],
    offices: ['recruiters', 'openings_statuses', 'candidates'],
    recruiters: ['openings_statuses', 'candidates'],
    openings_statuses: ['candidates'],
    candidates: [],
  };

  const onChangeFormValue = fields => {
    const fieldsToReset = {};
    filtersHierarchyObj[Object.keys(fields)[0]].forEach(field => {
      fieldsToReset[field] = initialFilterOptions[field];
    });
    form.setFieldsValue(fieldsToReset);
  };

  return (
    <Card className={cn('analytics-filter', tab.replace('/analytics/', ''))}>
      <Form
        name="analytics-filters"
        form={form}
        initialValues={initialFilterOptions}
        onValuesChange={onChangeFormValue}
        onFinish={onApply}
      >
        <Row className="select-block" gutter={[32, 16]}>
          <Col span={8}>
            <RangePicker label="Period" itemProps={{ name: 'period' }} allowClear={false} />
          </Col>
          {selectorsOptions.map(option => (
            <Col key={option.label} span={8}>
              <Select
                labelInValue
                placeholder="All"
                label={option.label}
                filterOption={false}
                options={loadingField === option.select ? [] : option.options}
                onSearch={search => onGetOptions(option.select, search)}
                onDropdownVisibleChange={open => open && getOptions(option.select)}
                disabled={option.label === 'Companies' && initialFilterOptions.companies.length}
                empty={loadingField === option.select ? <div>Loading...</div> : null}
                mode={SELECT_TYPES.MULTIPLE}
                itemProps={{ name: option.select }}
              />
            </Col>
          ))}
        </Row>
        <FooterButton onReset={onReset} />
      </Form>
    </Card>
  );
};
