import React, { createContext, useMemo, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Form } from 'antd';
import { ROUTES } from 'router/constants';
import {
  AnalyticsCandidateInfo,
  AnalyticsJobInfo,
  AnalyticsOpeningInfo,
  AnalyticsRecruitersInfo,
  AnalyticsTeamLeadsInfo,
  AnalyticsReportingInfo,
  AnalyticsSSCInfo,
} from '_templates/index';
import { Card, Tabs } from '_atoms';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ANALYTICS_TABS } from 'constants/constants';
import { ROLES, UI, ACTIONS, useAbility } from 'permission';
import { Typography } from '_atoms/Typography';
import { OPTIONS, PATHS } from './constans';
import history from '../../helpers/history';
import { AnalyticsFilter } from './AnalyticsFilter/analytics-filter';
import './analytics-dashboard.scss';

export const Context = createContext(null);

const AnalyticDashboard = ({ defaultServices }) => {
  const user = useSelector(getUser);
  const [form] = Form.useForm();

  const rmCompany = user.roles.find(
    item => item.name === ROLES.RESOURCE_MANAGER && item.pk === user.active_role.pk
  )?.company;
  const rmData = rmCompany ? [{ id: rmCompany.uuid, value: rmCompany.name, label: rmCompany.name }] : [];

  const initialFilterOptions = useMemo(
    () => ({
      period: [moment().startOf('month'), moment().startOf('day')],
      from_date: moment().startOf('month'),
      to_date: moment().startOf('day'),
      companies: rmData ? rmData.map(s => ({ value: s.id, label: s.label })) : [],
      recruiters: [],
      services: defaultServices,
      jobs: [],
      offices: [],
      candidates: [],
      team_leads: [],
      search: '',
      openings_statuses: [],
    }),
    [rmData, defaultServices]
  );

  const [appliedFilters, setAppliedFilters] = useState(initialFilterOptions);

  const ability = useAbility();
  const reportingAccess = ability.can(ACTIONS.READ, UI.ANALYTICS_REPORTING_TAB);
  const sscAccess = ability.can(ACTIONS.READ, UI.ANALYTICS_SSC_TAB);

  const tabs = useMemo(
    () =>
      reportingAccess && sscAccess
        ? OPTIONS
        : OPTIONS.filter(({ key }) => key !== ANALYTICS_TABS.REPORTING && key !== ANALYTICS_TABS.SSC),
    []
  );
  const activeTab = tabs?.find(({ key }) => location.pathname.includes(PATHS[key]))?.key || '';
  const setActiveTab = tab => {
    const { period, ...filtersToApply } = initialFilterOptions;
    setAppliedFilters(f => ({ ...filtersToApply, period: f.period }));
    form.setFieldsValue(filtersToApply);
    history.push(PATHS[tab]);
  };

  const contextValue = useMemo(() => ({ appliedFilters }), [appliedFilters]);

  const applyFilter = filters => {
    setAppliedFilters({ ...initialFilterOptions, ...filters });
  };

  const resetFilter = () => {
    setAppliedFilters(initialFilterOptions);
  };

  return (
    <div className="wrapper-content analytics-dashboard">
      <Card className="period-role">
        <Typography.Title1>Analytics</Typography.Title1>
        <Tabs className="role-filter" activeTab={activeTab} items={tabs} onTabClick={setActiveTab} />
      </Card>
      <AnalyticsFilter
        form={form}
        initialFilterOptions={initialFilterOptions}
        onApply={applyFilter}
        onReset={resetFilter}
      />
      <Context.Provider value={contextValue}>
        <Switch>
          <Route exact path={ROUTES.ANALYTICS} render={() => <Redirect to={ROUTES.ANALYTICS_RECRUITERS} />} />
          <Route path={ROUTES.ANALYTICS_RECRUITERS} component={AnalyticsRecruitersInfo} />
          <Route path={ROUTES.ANALYTICS_TEAM_LEADS} component={AnalyticsTeamLeadsInfo} />
          <Route path={ROUTES.ANALYTICS_JOB} component={AnalyticsJobInfo} />
          <Route path={ROUTES.ANALYTICS_CANDIDATE} component={AnalyticsCandidateInfo} />
          {reportingAccess && <Route path={ROUTES.ANALYTICS_REPORTING} component={AnalyticsReportingInfo} />}
          <Route path={ROUTES.ANALYTICS_OPENINGS} component={AnalyticsOpeningInfo} />
          {sscAccess && <Route path={ROUTES.ANALYTICS_SSC} component={AnalyticsSSCInfo} />}
          <Route render={({ location }) => <Redirect to={{ ...location, state: { is404: true } }} />} />
        </Switch>
      </Context.Provider>
    </div>
  );
};

export default AnalyticDashboard;
