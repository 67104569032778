import React, { useState } from 'react';
import cn from 'classnames';
import { Button, Card, AtsSkeleton, Popup } from '_atoms';
import { ReactComponent as Upload } from '../../../dist/icons/upload.svg';
import Table from '../../../containers/Analytics/Table';
import { ReactComponent as Vector } from '../../../dist/icons/Vector1.svg';
import { analyticWidgets } from '../../../actions/analyticActions';
import { AnalyticsEmpty } from '../AnalyticsEmpty/analytics-empty';
import Date from '../../../dist/images/common/icons/graph.svg';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from '../../../constants/text';

const tableHeader = [
  { label: 'Job', dataKey: 'job', sticky: true, width: 264, sortable: true },
  { label: 'Candidate name', dataKey: 'candidate_full_name', sticky: true, width: 168, sortable: true },
  { label: 'Recruiter', dataKey: 'responsible_recruiter', width: 168 },
  { label: 'Step', dataKey: 'step', width: 220 },
  { label: 'Step status', dataKey: 'step_status', width: 220 },
  { label: 'Assignment date', dataKey: 'assignment_date', width: 168, sortable: true },
  {
    label: 'Interviews',
    dataKey: 'interviews',
    width: 168,
  },
  { label: 'Assignment comment', dataKey: 'comment', width: 168 },
  {
    label: 'Job offer',
    dataKey: 'has_offer',
    width: 168,
    // sortable: true,
  },
  {
    label: 'Offer feedback',
    dataKey: 'offer_decision',
    width: 168,
    // sortable: true,
  },
  { label: 'Result', dataKey: 'result', width: 264 },
];

const SORT = {
  DESC: 'desc',
  ASC: 'asc',
};

export const AnalyticsTable = ({ filters, loading, candidates, options, setOptions }) => {
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);

  const increment = () => setOptions({ offset: options.offset + options.limit });
  const decrement = () => setOptions({ offset: options.offset - options.limit });
  const changeSorting = ({ key, field }) => {
    if (loading) return;
    if (options.sorting.key === key)
      setOptions({
        sorting: { key: options.sorting.key, order: options.sorting.order === SORT.ASC ? SORT.DESC : SORT.ASC, field },
      });
    else setOptions({ sorting: { key, order: SORT.ASC, field } });
  };

  const exportExcel = () => {
    analyticWidgets('candidates/export/xlsx', filters).then(() => {
      setSuccessPopupOpen(true);
      setExportPopupOpen(false);
    });
  };

  if (!loading && !candidates.length) {
    return (
      <AnalyticsEmpty
        text="Please try different period or filters"
        title="There are no results for the specified parameters"
        className="shadow"
        img={Date}
      />
    );
  }

  return (
    <>
      <Card className="analytics-dashboard-table">
        <div className="export-excel__wrapper">
          {loading ? (
            <AtsSkeleton height={36} width={116} />
          ) : (
            <div>
              <div className="export-excel__title_extra-wrapper">
                <span className="export-excel__title">Candidates assigned to jobs</span>
              </div>
              <span className="export-excel__subtitle">({options.count})</span>
            </div>
          )}
          {loading ? (
            <AtsSkeleton height={36} width={116} />
          ) : (
            <Button onClick={() => setExportPopupOpen(true)} icon={<Upload />} className="export-excel">
              {BUTTON_TEXT.EXPORT_EXCEL}
            </Button>
          )}
        </div>
        {loading ? (
          <div className="analytics-dashboard-table__loading">
            <AtsSkeleton height={368} />
          </div>
        ) : (
          <Table sorting={[options.sorting]} data={candidates} header={tableHeader} onChangeSort={changeSorting} />
        )}
      </Card>
      <Card className="analytics-dashboard-table pagination">
        {loading ? (
          <AtsSkeleton height={24} width={144} />
        ) : (
          <>
            <Vector onClick={options.prev ? decrement : null} className={cn('vector', { disable: !options.prev })} />
            <Vector
              onClick={options.next ? increment : null}
              className={cn('vector rotate', { disable: !options.next })}
            />
            <span>{`${options.offset + 1} – ${options.offset + candidates.length} out of ${options.count}`}</span>
          </>
        )}
      </Card>
      <Popup
        open={exportPopupOpen}
        title={POPUP_LABELS.EXPORT_EXCEL}
        cancelButtonProps={{ onClick: () => setExportPopupOpen(false), children: BUTTON_TEXT.GO_BACK }}
        okButtonProps={{ onClick: exportExcel, children: BUTTON_TEXT.SEND_TO_EMAIL }}
      >
        {POPUP_BODY.EXPORT_EXCEL}
      </Popup>
      <Popup
        open={successPopupOpen}
        title={POPUP_LABELS.SUCCESS}
        okButtonProps={{ onClick: () => setSuccessPopupOpen(false), children: BUTTON_TEXT.OK }}
      >
        {POPUP_BODY.FILE_SENT}
      </Popup>
    </>
  );
};
