import moment from 'moment';
import { dateFormatter } from 'helpers/dateFormatter';
import { OPENING_STATUS_TYPES } from 'constants/opening';

export function candidateMap(resp) {
  return resp.results.map(candidate => ({
    job: { data: candidate.job },
    candidate_full_name: { data: candidate.candidate_full_name },
    responsible_recruiter: { data: candidate.responsible_recruiter?.recruiter_full_name },
    step: { data: candidate.step_name },
    step_status: { data: candidate.step_status },
    assignment_date: { data: moment(candidate.assignment_date).format('MMM DD, YYYY') },
    interviews: {
      data: candidate.interviews[0]?.date
        ? moment(candidate.interviews[0]?.date).format('MMM DD, YYYY')
        : candidate.is_waiting_interview
        ? 'Waiting for interview'
        : '—',
    },
    comment: { data: candidate.assignment_comment || '-', reachText: true },
    has_offer: {
      data: candidate.has_offer === null ? '—' : candidate.has_offer ? 'Yes' : 'No',
      className: candidate.has_offer === null ? '' : candidate.has_offer ? 'success' : 'error',
    },
    offer_decision: {
      data: ['Accepted', 'Declined'].includes(candidate.offer_decision)
        ? candidate.offer_decision
        : candidate.offer_decision
        ? moment(candidate.offer_decision).format('MMM DD, YYYY')
        : '—',
      className:
        candidate.offer_decision === 'Accepted' ? 'success' : candidate.offer_decision === 'Declined' ? 'error' : null,
    },
    result: {
      data:
        candidate.offer_decision === 'Accepted'
          ? !candidate.decision_info.positive
            ? candidate.decision_info.reason
            : moment(candidate.decision_info.entry_date).format('MMM DD, YYYY')
          : candidate.offer_decision === 'Declined'
          ? candidate.decision_info.reason
          : '—',
    },
  }));
}

export function openingMap(resp) {
  return resp.results.map(o => ({
    priority: o.priority,
    job_name: o.job_name,
    team_lead: o.team_lead_name,
    expected_date: dateFormatter(o.expected, 'DD.MM.YYYY'),
    technical_level: o.level,
    status: {
      type: OPENING_STATUS_TYPES[o.status],
      text: o.status,
    },
    creation_date: dateFormatter(o.created_at, 'DD.MM.YYYY'),
    office_location: o.offices.map(el => el.name).join(', '),
    closure_date: o.closed ? dateFormatter(o.closed, 'DD.MM.YYYY') : '',
    potential_candidates: o.potential_candidates_count,
  }));
}
