import React, { useState } from 'react';
import cn from 'classnames';
import { VariableSizeGrid as Table } from 'react-window';
import { TableFooter } from '_molecules';
import { Button, Card, Tooltip, AtsSkeleton, Popup } from '_atoms';
import { AnalyticsEmpty } from '_templates/AnalyticsDashboard/AnalyticsEmpty/analytics-empty';
import { analyticWidgets } from 'actions/analyticActions';
import { Priority } from '_atoms/Priority';
import { Status } from '_atoms/Status';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { BUTTON_TEXT, POPUP_BODY, POPUP_LABELS } from 'constants/text';
import { ReactComponent as Upload } from 'dist/icons/upload.svg';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import Date from 'dist/images/common/icons/graph.svg';
import './style.scss';

const SORT = {
  DESC: 'desc',
  ASC: 'asc',
};

const columns = [
  {
    label: 'Priority',
    field: 'Priority',
    key: 'priority',
    width: 92,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        <Priority priority={data} />
      </div>
    ),
  },
  {
    label: 'Job name',
    field: 'Job name',
    key: 'job_name',
    width: 176,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        <Tooltip label={data}>
          <div className="ellipsis">{data}</div>
        </Tooltip>
      </div>
    ),
  },
  {
    label: 'Team Lead',
    field: 'Team Lead',
    key: 'team_lead',
    width: 232,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        {data}
      </div>
    ),
  },
  {
    label: 'Expected date',
    field: 'Expected date',
    key: 'expected_date',
    width: 176,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        {data}
      </div>
    ),
  },
  {
    label: 'Required experience level',
    field: 'Level',
    key: 'technical_level',
    width: 216,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        {data}
      </div>
    ),
  },
  {
    label: 'Status',
    field: 'Status',
    key: 'status',
    width: 156,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        <Status type={data.type}>{data.text}</Status>
      </div>
    ),
  },
  {
    label: 'Creation date',
    field: 'Creation date',
    key: 'creation_date',
    width: 176,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        {data}
      </div>
    ),
  },
  {
    label: 'Office location',
    field: 'Office location',
    key: 'office_location',
    width: 176,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        {data}
      </div>
    ),
  },
  {
    label: 'Closure date',
    field: 'Closure date',
    key: 'closure_date',
    width: 176,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={style}>
        {data}
      </div>
    ),
  },
  {
    label: 'Potential candidates',
    field: 'Potential candidates',
    key: 'potential_candidates',
    width: 176,
    sortable: true,
    itemRender: ({ style, data }) => (
      <div className="cell-default text-body ellipsis" style={{ ...style, fontWeight: 600 }}>
        {data}
      </div>
    ),
  },
];

const OpeningsTable = ({ filters, loading, openings, options, setOptions }) => {
  const rowHeight = 54;
  const tableWidth = columns.reduce((sum, c) => sum + c.width, 0);
  const [exportPopupOpen, setExportPopupOpen] = useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const setItemsPerPage = item => {
    setOptions({ limit: item, offset: 0, pageToShow: 1 });
  };

  const setPage = page => {
    setOptions({ offset: options.limit.value * (page - 1), pageToShow: page });
  };
  const onSortClick = ({ key, field }) => {
    if (loading) return;
    if (options.sorting.key === key)
      setOptions({
        sorting: { key: options.sorting.key, order: options.sorting.order === SORT.ASC ? SORT.DESC : SORT.ASC, field },
      });
    else setOptions({ sorting: { key, order: SORT.ASC, field } });
  };

  const exportExcel = () => {
    analyticWidgets('openings/export/xlsx', {
      ...filters,
      sorting: [{ field: options.sorting.field, order: options.sorting.order }],
    }).then(() => {
      setSuccessPopupOpen(true);
      setExportPopupOpen(false);
    });
  };

  if (!loading && !openings.length) {
    return (
      <AnalyticsEmpty
        text="Please try different period or filters"
        title="There are no results for the specified parameters"
        className="shadow"
        img={Date}
      />
    );
  }

  return (
    <>
      <Card className="analytics-dashboard-table openings-table">
        <div className="export-excel__wrapper">
          {loading ? (
            <AtsSkeleton height={36} width={116} />
          ) : (
            <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD} className="openings-table__title">
              Openings<span className="openings-table__subtitle">({options.count})</span>
            </Typography.Title2>
          )}
          {loading ? (
            <AtsSkeleton height={36} width={116} />
          ) : (
            <Button onClick={() => setExportPopupOpen(true)} icon={<Upload />} className="export-excel">
              {BUTTON_TEXT.EXPORT_EXCEL}
            </Button>
          )}
        </div>
        <div className="table-default">
          {loading && !openings.length ? (
            <div className="analytics-dashboard-table__loading">
              <AtsSkeleton height={368} />
            </div>
          ) : (
            <>
              <div className="table-default__header" style={{ width: tableWidth }}>
                {columns.map(el => (
                  <span key={el.key} className="table-default__header-cell" style={{ width: el.width }}>
                    {el.label}
                    {el.sortable && (
                      <Sort
                        onClick={() => onSortClick(el)}
                        className={cn('filter_arrows', {
                          [options.sorting.order]: options.sorting.key === el.key,
                        })}
                      />
                    )}
                  </span>
                ))}
              </div>
              <Table
                columnCount={columns.length}
                rowCount={openings.length}
                height={openings.length * rowHeight}
                width={tableWidth}
                rowHeight={() => rowHeight}
                columnWidth={col => columns[col].width}
              >
                {props =>
                  loading ? (
                    <LoadingCell {...props} />
                  ) : (
                    <Cell {...props} data={openings[props.rowIndex]} column={columns[props.columnIndex]} />
                  )
                }
              </Table>
            </>
          )}
        </div>
        <TableFooter
          loading={loading}
          limit={options.limit}
          offset={options.offset}
          page={options.pageToShow}
          count={openings.length}
          onChangePage={setPage}
          itemsCount={options.count}
          onChangeLimit={setItemsPerPage}
        />
      </Card>
      <Popup
        open={exportPopupOpen}
        title={POPUP_LABELS.EXPORT_EXCEL}
        cancelButtonProps={{ onClick: () => setExportPopupOpen(false), children: BUTTON_TEXT.GO_BACK }}
        okButtonProps={{ onClick: exportExcel, children: BUTTON_TEXT.SEND_TO_EMAIL }}
      >
        {POPUP_BODY.EXPORT_EXCEL}
      </Popup>
      <Popup
        open={successPopupOpen}
        title={POPUP_LABELS.SUCCESS}
        confirmTxt={BUTTON_TEXT.OK}
        okButtonProps={{ onClick: () => setSuccessPopupOpen(false), children: BUTTON_TEXT.OK }}
      >
        {POPUP_BODY.FILE_SENT}
      </Popup>
    </>
  );
};

const LoadingCell = ({ style }) => {
  const { height, width } = style;
  return (
    <div className="cell-default" style={style}>
      <AtsSkeleton height={height - 20} width={width - 24} style={{ display: 'block' }} />
    </div>
  );
};
const Cell = ({ data, column, style }) => {
  const Item = column.itemRender;
  return <Item style={style} data={data[column.key]} />;
};

export default OpeningsTable;
