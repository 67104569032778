import React from 'react';
import moment from 'moment';
import cn from 'classnames';
import { Col, Form, Row } from 'antd';
import { Button, Card, Checkbox, DatePicker, Select, RichArea, BUTTON_TYPES } from '_atoms';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { VALIDATION_MAX_100 } from '_organisms/CandidateCreationForm/constants';
import { ReactComponent as Bin } from 'dist/icons/bin.svg';
import { ReactComponent as Union } from 'dist/icons/Union.svg';
import './style.scss';

export const ExperienceList = ({ companiesOptions, positionsOptions, maxCount = 5 }) => (
  <Form.List name="experience">
    {(fields, { add, remove }) => (
      <div className="experience-list">
        {fields.map((field, index) => (
          <Card key={field.key} className={cn('experience-list__item', { 'no-delete': !index })}>
            <Row gutter={[32, 0]}>
              <Col span={24} lg={12} md={12} sm={24}>
                <Select
                  onChange={() => document.activeElement.blur()}
                  className="single-select-tags"
                  labelInValue
                  label="Company"
                  options={companiesOptions}
                  maxTagCount={1}
                  itemProps={{
                    ...field,
                    name: [field.name, 'company'],
                    fieldKey: [field.fieldKey, 'company'],
                    rules: [VALIDATION_MAX_100],
                  }}
                  mode={SELECT_TYPES.CREATABLE}
                  allowClear
                />
                <Checkbox
                  label="Currently working"
                  itemProps={{ ...field, name: [field.name, 'is_current'], fieldKey: [field.fieldKey, 'is_current'] }}
                  // onChange={e => onCurrent(field.name, e.target.checked)}
                />
              </Col>
              <Col span={24} lg={12} md={12} sm={24}>
                <Select
                  onChange={() => document.activeElement.blur()}
                  className="single-select-tags"
                  labelInValue
                  label="Position"
                  options={positionsOptions}
                  maxTagCount={1}
                  itemProps={{
                    ...field,
                    name: [field.name, 'position'],
                    fieldKey: [field.fieldKey, 'position'],
                    rules: [VALIDATION_MAX_100],
                  }}
                  mode={SELECT_TYPES.CREATABLE}
                  allowClear
                />
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={24} lg={12} md={12} sm={24}>
                <Row gutter={[16, 0]}>
                  <Col span={24} lg={12} md={12} sm={24}>
                    <DatePicker
                      label="Start date"
                      itemProps={{
                        ...field,
                        name: [field.name, 'worked_from'],
                        fieldKey: [field.fieldKey, 'worked_from'],
                        initialValue: moment(),
                      }}
                    />
                  </Col>
                  <Col span={24} lg={12} md={12} sm={24}>
                    <Form.Item noStyle shouldUpdate={(prev, curr) => prev.experience !== curr.experience}>
                      {({ getFieldValue }) => (
                        <DatePicker
                          label="End date"
                          itemProps={{
                            ...field,
                            name: [field.name, 'worked_till'],
                            fieldKey: [field.fieldKey, 'worked_till'],
                            initialValue: moment(),
                          }}
                          disabled={getFieldValue('experience')?.[field.name]?.is_current}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col span={24} lg={12} md={12} sm={24}>
                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.experience !== curr.experience}>
                  {({ getFieldValue, setFieldValue }) => (
                    <Form.Item noStyle {...field} name={[field.name, 'additional_info']}>
                      <RichArea
                        label="Additional Information"
                        placeholder="Add a comment"
                        maxLength={2000}
                        initialValue={getFieldValue('experience')?.[field.name]?.additional_info_initial}
                        onChange={v => setFieldValue([field.name, 'additional_info'], v)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Button
              type={BUTTON_TYPES.GHOST}
              onClick={() => remove(field.name)}
              className="experience-list__item__delete"
              icon={<Bin />}
            />
          </Card>
        ))}
        {fields.length < maxCount && (
          <Button icon={<Union />} onClick={() => add()} className="experience-list__add">
            Add working experience
          </Button>
        )}
      </div>
    )}
  </Form.List>
);
