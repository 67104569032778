import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Table, Select, Divider, Tabs } from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { useQuery } from 'helpers/useLocation';
import useScreenResize from 'helpers/useScreenResize';
import {
  declineApproveOpening,
  getAllCompanies,
  getSAJobsTableDashboardAPI,
  getSAServicesTableDashboardAPI,
} from 'actions';
import { PopupCloseDeclineOpening } from '_molecules';
import { SelfSubmittedCandidatesDashboard } from '_organisms';
import { getCompanies } from 'store/selectors/commonSelectors';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import { jobTableDataMapper, serviceTableDataMapper, isServiceGrouping } from './utils';
import {
  COLUMNS_OPENINGS_SERVICE,
  INITIAL_VALUES_1,
  OPENINGS_GROUP,
  COLUMNS_OPENINGS_SERVICE_TOP,
  COLUMNS_OPENINGS_JOB_TOP,
  INITIAL_VALUES_2,
  COLUMNS_OPENINGS_JOB,
  OPENING_STATUSES_OPTIONS,
  COMPANY_ALL,
  TABS,
} from './constants';
import './style.scss';

export const DashboardSuperAdmin = () => {
  const query = useQuery();
  const [tab, setTab] = useState(query.tab || TABS[0].key);

  return (
    <div className="dashboard-super-admin">
      <Tabs className="dashboard-recruiter-lead__tabs" activeTab={tab} onChange={setTab} items={TABS} />
      {
        {
          [TABS[0].key]: <AllOpeningsService />,
          [TABS[1].key]: <SelfSubmittedCandidatesDashboard dashboard="super-admin" />,
        }[tab]
      }
    </div>
  );
};

const AllOpeningsService = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES_1);
  const [openingStatus, setOpeningStatus] = useState([
    OPENING_STATUSES_OPTIONS[0],
    OPENING_STATUSES_OPTIONS[1],
    OPENING_STATUSES_OPTIONS[2],
    OPENING_STATUSES_OPTIONS[3],
  ]);
  const [company, setCompany] = useState(COMPANY_ALL);
  const [grouping, setGrouping] = useState({ ...OPENINGS_GROUP[0], tableData: OPENINGS_GROUP[1].tableData });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showOpeningDeclinePopup, setShowOpeningDeclinePopup] = useState({});

  const companies = useSelector(getCompanies);
  const companyOptions = useMemo(
    () => [COMPANY_ALL, ...companies.map(c => ({ value: c.uuid, label: c.name }))],
    [companies]
  );

  const count = useMemo(() => tableData.reduce((sum, data) => sum + data.openings.length, 0), [tableData]);

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onClickTitle =
    ({ uuid, name }) =>
    () => {
      if (isServiceGrouping(grouping.value))
        window.open(`/jobs?services=[{"value":"${uuid}","label":"${name}"}]`, '_blank', 'noopener noreferrer');
      else window.open(`/jobs/${uuid}/show/openings`, '_blank', 'noopener noreferrer');
    };

  const onChangeGrouping = v => {
    setGrouping(v);
    getTableData(v);
  };

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      const options = { ...tableOptions, page: item, offset: (item - 1) * tableOptions.limit.value };
      setTableOptions(options);
      getTableData(grouping, options);
    } else if (field === 'limit') {
      const options = { ...tableOptions, limit: item, offset: 0, page: 1 };
      setTableOptions(options);
      getTableData(grouping, options);
    } else {
      const options = { ...tableOptions, [field]: item };
      setTableOptions(options);
      getTableData(grouping, options);
    }
  };

  const onOpeningAction = ({ job_uuid, service_uuid, ...d }, type) => {
    if (type === 'approve') {
      declineApproveOpening(job_uuid, service_uuid, type).then(() => getTableData());
    } else {
      setShowOpeningDeclinePopup({ ...d, job_uuid, service_uuid, type });
    }
  };

  const getTableData = (group = grouping, options = tableOptions) => {
    setLoading(true);
    if (isServiceGrouping(group.value)) {
      getSAServicesTableDashboardAPI({
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
        company: company.value,
      })
        .then(d => {
          setTableOptions(o =>
            group.value !== group.tableData
              ? { ...INITIAL_VALUES_1, itemsCount: d.count }
              : { ...o, itemsCount: d.count }
          );
          const data = serviceTableDataMapper(d.results);
          setTableData(data);
          setGrouping(g => ({ ...g, tableData: g.value }));
          setExpandedRowKeys(data.map(({ uuid }) => uuid));
        })
        .catch(() => {
          setTableOptions(INITIAL_VALUES_1);
          setTableData([]);
          setGrouping(g => ({ ...g, tableData: g.value }));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getSAJobsTableDashboardAPI({
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
        company: company.value,
      })
        .then(d => {
          setTableOptions(o =>
            group.value !== group.tableData
              ? { ...INITIAL_VALUES_2, itemsCount: d.count }
              : { ...o, itemsCount: d.count }
          );
          const data = jobTableDataMapper(d.results);
          setTableData(data);
          setGrouping(g => ({ ...g, tableData: g.value }));
          setExpandedRowKeys(data.map(({ uuid }) => uuid));
        })
        .catch(() => {
          setTableOptions(INITIAL_VALUES_2);
          setTableData([]);
          setGrouping(g => ({ ...g, tableData: g.value }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCloseCloseOpeningPopup = () => setShowOpeningDeclinePopup({});
  const onDeclineOpening = reason => {
    const { job_uuid, service_uuid, type } = showOpeningDeclinePopup;
    declineApproveOpening(job_uuid, service_uuid, type, { reason }).then(() => getTableData());
    setShowOpeningDeclinePopup({});
  };

  useEffect(() => dispatch(getAllCompanies()), []);
  useEffect(getTableData, [openingStatus, company]);
  useScreenResize('md', tableRef);

  return (
    <>
      <Table
        innerRef={tableRef}
        rowKey="uuid"
        className={cn('all-openings', grouping.tableData)}
        cardTitle="Openings"
        cardCount={tableOptions.itemsCount}
        emptyComponent={<OpeningsTableEmpty />}
        extra={
          <>
            <Select
              labelInValue
              options={OPENING_STATUSES_OPTIONS}
              value={openingStatus}
              onChange={setOpeningStatus}
              mode={SELECT_TYPES.MULTIPLE}
            />
            <div className="filter-selectors">
              <div className="filter-container">
                <SelectSmall
                  label="Company"
                  options={companyOptions}
                  value={company}
                  onChange={setCompany}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
              <Divider type="vertical" />
              <div className="filter-container">
                <SelectSmall
                  label="Group by"
                  options={OPENINGS_GROUP}
                  value={grouping}
                  onChange={onChangeGrouping}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
            </div>
          </>
        }
        showCardFooter={tableData.length}
        columns={isServiceGrouping(grouping.tableData) ? COLUMNS_OPENINGS_SERVICE_TOP : COLUMNS_OPENINGS_JOB_TOP}
        loading={loading}
        data={tableData}
        limit={tableOptions.limit}
        page={tableOptions.page}
        count={count}
        offset={tableOptions.offset}
        itemsCount={tableOptions.itemsCount}
        updateParams={onChangeFilter}
        expandable={{
          expandedRowKeys,
          expandedRowRender: ({ openings }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('all-openings__sub-table', grouping.tableData)}
                columns={
                  isServiceGrouping(grouping.tableData)
                    ? COLUMNS_OPENINGS_SERVICE(onOpeningAction)
                    : COLUMNS_OPENINGS_JOB(onOpeningAction)
                }
                data={openings}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('all-openings__sub-table all-openings__sub-table-mobile', grouping.tableData)}
                data={openings}
                columns={
                  isServiceGrouping(grouping.tableData)
                    ? COLUMNS_OPENINGS_SERVICE(onOpeningAction)
                    : COLUMNS_OPENINGS_JOB(onOpeningAction)
                }
                empty={<OpeningsTableEmpty />}
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name" onClick={onClickTitle(record)}>
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.service && <Typography.Text className="name__extra">{record.service}</Typography.Text>}
              </div>
              <div className="expanded-row__extra">
                <Typography.Caption>Total active candidates: {record.candidates_count}</Typography.Caption>
                <Typography.Caption>Total openings: {record.openings_count}</Typography.Caption>
              </div>
            </div>
          ),
        }}
      />
      <PopupCloseDeclineOpening
        open={!!showOpeningDeclinePopup.opening}
        opening={showOpeningDeclinePopup.opening}
        jobName={showOpeningDeclinePopup.job_name}
        onClose={onCloseCloseOpeningPopup}
        confirm={onDeclineOpening}
      />
    </>
  );
};

const OpeningsTableEmpty = () => (
  <div className="all-openings__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no openings here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);
