import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Table, Select, Tabs, Checkbox } from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { PLACEMENTS, SelectSmall } from '_atoms/SelectSmall';
import { SELECT_TYPES } from '_atoms/Select/constants';
import { PopupCloseDeclineOpening } from '_molecules';
import {
  declineApproveOpening,
  getSHCandidatesTableDashboardAPI,
  getSHJobsTableDashboardAPI,
  getSHServicesTableDashboardAPI,
} from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import useScreenResize from 'helpers/useScreenResize';
import { ROLES } from 'permission';
import { ReactComponent as JobImage } from 'dist/emptyStates/dashboardOpening.svg';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import {
  jobTableDataMapper,
  serviceTableDataMapper,
  isServiceGrouping,
  useTabs,
  CANDIDATES_TABLES_MAPPERS,
} from './utils';
import {
  COLUMNS_OPENINGS_SERVICE,
  INITIAL_VALUES_1,
  OPENINGS_GROUP,
  COLUMNS_OPENINGS_SERVICE_TOP,
  COLUMNS_OPENINGS_JOB_TOP,
  INITIAL_VALUES_2,
  COLUMNS_OPENINGS_JOB,
  OPENING_STATUSES_OPTIONS,
  INITIAL_VALUES,
  CANDIDATES_TABLE_COLUMNS,
  CANDIDATES_GROUP,
} from './constants';
import './style.scss';

export const DashboardServiceHead = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('openings');
  const tabs = useTabs(loading);

  return (
    <div className="dashboard-service-head">
      <Tabs className="dashboard-service-head__tabs" activeTab={tab} onChange={setTab} items={tabs} />
      {tab === tabs[0].key && <AllOpeningsService setTabsLoading={setLoading} />}
      {tab === tabs[1].key && <MyActiveCandidates setTabsLoading={setLoading} />}
    </div>
  );
};

const AllOpeningsService = ({ setTabsLoading }) => {
  const tableRef = useRef(null);

  const user = useSelector(getUser);
  const showGrouping =
    [...new Set(user.roles.filter(r => r.name === ROLES.SERVICE_HEAD && r.service).map(item => item.service.uuid))]
      .length > 1;

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES_1);
  const [openingStatus, setOpeningStatus] = useState([OPENING_STATUSES_OPTIONS[0]]);
  const [grouping, setGrouping] = useState(
    showGrouping
      ? { ...OPENINGS_GROUP[0], tableData: OPENINGS_GROUP[1].tableData }
      : { ...OPENINGS_GROUP[1], tableData: OPENINGS_GROUP[0].tableData }
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [showOpeningDeclinePopup, setShowOpeningDeclinePopup] = useState({});

  const count = useMemo(() => tableData.reduce((sum, data) => sum + data.openings.length, 0), [tableData]);

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onClickTitle =
    ({ uuid, name }) =>
    () => {
      if (isServiceGrouping(grouping.value))
        window.open(`/jobs?services=[{"value":"${uuid}","label":"${name}"}]`, '_blank', 'noopener noreferrer');
      else window.open(`/jobs/${uuid}/show/openings`, '_blank', 'noopener noreferrer');
    };

  const onChangeGrouping = v => {
    setGrouping(v);
    getTableData(v);
  };

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      const options = { ...tableOptions, page: item, offset: (item - 1) * tableOptions.limit.value };
      setTableOptions(options);
      getTableData(grouping, options);
    } else if (field === 'limit') {
      const options = { ...tableOptions, limit: item, offset: 0, page: 1 };
      setTableOptions(options);
      getTableData(grouping, options);
    } else {
      const options = { ...tableOptions, [field]: item };
      setTableOptions(options);
      getTableData(grouping, options);
    }
  };

  const onOpeningAction = ({ job_uuid, service_uuid, ...d }, type) => {
    if (type === 'approve') {
      declineApproveOpening(job_uuid, service_uuid, type).then(() => getTableData());
    } else {
      setShowOpeningDeclinePopup({ ...d, job_uuid, service_uuid, type });
    }
  };

  const getTableData = (group = grouping, options = tableOptions) => {
    setLoading(true);
    setTabsLoading(true);
    if (isServiceGrouping(group.value)) {
      getSHServicesTableDashboardAPI({
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
      })
        .then(d => {
          setTableOptions(o =>
            group.value !== group.tableData
              ? { ...INITIAL_VALUES_1, itemsCount: d.count }
              : { ...o, itemsCount: d.count }
          );
          const data = serviceTableDataMapper(d.results);
          setTableData(data);
          setGrouping(g => ({ ...g, tableData: g.value }));
          setExpandedRowKeys(data.map(({ uuid }) => uuid));
        })
        .catch(() => {
          setTableOptions(INITIAL_VALUES_1);
          setTableData([]);
          setGrouping(g => ({ ...g, tableData: g.value }));
        })
        .finally(() => {
          setLoading(false);
          setTabsLoading(false);
        });
    } else {
      getSHJobsTableDashboardAPI({
        limit: options.limit.value,
        offset: options.offset,
        status: openingStatus.map(({ value }) => value).join(','),
      })
        .then(d => {
          setTableOptions(o =>
            group.value !== group.tableData
              ? { ...INITIAL_VALUES_2, itemsCount: d.count }
              : { ...o, itemsCount: d.count }
          );
          const data = jobTableDataMapper(d.results);
          setTableData(data);
          setGrouping(g => ({ ...g, tableData: g.value }));
          setExpandedRowKeys(data.map(({ uuid }) => uuid));
        })
        .catch(() => {
          setTableOptions(INITIAL_VALUES_2);
          setTableData([]);
          setGrouping(g => ({ ...g, tableData: g.value }));
        })
        .finally(() => {
          setLoading(false);
          setTabsLoading(false);
        });
    }
  };

  const onCloseCloseOpeningPopup = () => setShowOpeningDeclinePopup({});
  const onDeclineOpening = reason => {
    const { job_uuid, service_uuid, type } = showOpeningDeclinePopup;
    declineApproveOpening(job_uuid, service_uuid, type, { reason }).then(() => getTableData());
    setShowOpeningDeclinePopup({});
  };

  useEffect(getTableData, [openingStatus]);
  useScreenResize('md', tableRef);

  return (
    <>
      <Table
        innerRef={tableRef}
        rowKey="uuid"
        className={cn('all-openings', grouping.tableData)}
        cardTitle="Openings"
        cardCount={tableOptions.itemsCount}
        emptyComponent={<OpeningsTableEmpty />}
        extra={
          <div className="all-openings__header-extra">
            <Select
              labelInValue
              options={OPENING_STATUSES_OPTIONS}
              value={openingStatus}
              onChange={setOpeningStatus}
              mode={SELECT_TYPES.MULTIPLE}
              clearIcon={<div />}
            />
            {showGrouping && (
              <div className="filter-container">
                <SelectSmall
                  label="Group by"
                  options={OPENINGS_GROUP}
                  value={grouping}
                  onChange={onChangeGrouping}
                  placement={PLACEMENTS.BOTTOM_RIGHT}
                />
              </div>
            )}
          </div>
        }
        showCardFooter={tableData.length}
        columns={isServiceGrouping(grouping.tableData) ? COLUMNS_OPENINGS_SERVICE_TOP : COLUMNS_OPENINGS_JOB_TOP}
        loading={loading}
        data={tableData}
        limit={tableOptions.limit}
        page={tableOptions.page}
        count={count}
        offset={tableOptions.offset}
        itemsCount={tableOptions.itemsCount}
        updateParams={onChangeFilter}
        expandable={{
          expandedRowKeys,
          expandedRowRender: ({ openings }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('all-openings__sub-table', grouping.tableData)}
                columns={
                  isServiceGrouping(grouping.tableData)
                    ? COLUMNS_OPENINGS_SERVICE(onOpeningAction)
                    : COLUMNS_OPENINGS_JOB(onOpeningAction)
                }
                data={openings}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('all-openings__sub-table all-openings__sub-table-mobile', grouping.tableData)}
                data={openings}
                columns={
                  isServiceGrouping(grouping.tableData)
                    ? COLUMNS_OPENINGS_SERVICE(onOpeningAction)
                    : COLUMNS_OPENINGS_JOB(onOpeningAction)
                }
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name" onClick={onClickTitle(record)}>
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.service && showGrouping && (
                  <Typography.Text className="name__extra">{record.service}</Typography.Text>
                )}
              </div>
              <div className="expanded-row__extra">
                <Typography.Caption>Total active candidates: {record.candidates_count}</Typography.Caption>
                <Typography.Caption>Total openings: {record.openings_count}</Typography.Caption>
              </div>
            </div>
          ),
        }}
      />
      <PopupCloseDeclineOpening
        open={!!showOpeningDeclinePopup.opening}
        opening={showOpeningDeclinePopup.opening}
        jobName={showOpeningDeclinePopup.job_name}
        onClose={onCloseCloseOpeningPopup}
        confirm={onDeclineOpening}
      />
    </>
  );
};

const MyActiveCandidates = ({ setTabsLoading }) => {
  const tableRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES);
  const [count, setCount] = useState(0);
  const [grouping, setGrouping] = useState(CANDIDATES_GROUP[0]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      setTableOptions(s => ({ ...s, page: item, offset: (item - 1) * s.limit.value }));
    } else if (field === 'limit') {
      setTableOptions(s => ({ ...s, limit: item, offset: 0, page: 1 }));
    } else if (field === 'only_with_actions') {
      setTableOptions(s => ({ ...s, only_with_actions: item, offset: 0, page: 1 }));
    } else {
      setTableOptions(s => ({ ...s, [field]: item }));
    }
  };
  const setSorting = sort => onChangeFilter('sorting', sort);
  const onChangeWithActions = e => onChangeFilter('only_with_actions', e.target.checked);

  const onExpand =
    ({ uuid }) =>
    () => {
      if (!expandedRowKeys.includes(uuid)) setExpandedRowKeys(rows => [...rows, uuid]);
      else setExpandedRowKeys(rows => rows.filter(r => r !== uuid));
    };

  const onGroupingChange = newGrouping => {
    setGrouping(g => ({ ...newGrouping, tableData: g.tableData }));
  };

  const getTableData = () => {
    setLoading(true);
    setTabsLoading(true);
    getSHCandidatesTableDashboardAPI(
      {
        limit: tableOptions.limit.value,
        offset: tableOptions.offset,
        sorting_field: tableOptions.sorting.field,
        sorting: tableOptions.sorting.order,
        only_with_actions: tableOptions.only_with_actions,
      },
      grouping.value
    )
      .then(d => {
        setTableData([]);
        setTableOptions(o => ({ ...o, itemsCount: d.count }));
        setCount(d.count);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableData(CANDIDATES_TABLES_MAPPERS[grouping.value](d.results));
        setExpandedRowKeys(d.results.map((_, index) => index));
      })
      .catch(() => {
        setTableData([]);
        setCount(0);
        setGrouping(g => ({ ...g, tableData: g.value }));
        setTableOptions(INITIAL_VALUES);
      })
      .finally(() => {
        setLoading(false);
        setTabsLoading(false);
      });
  };

  useEffect(getTableData, [
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    tableOptions.only_with_actions,
    grouping.value,
  ]);
  useScreenResize('md', tableRef);

  return (
    <Table
      innerRef={tableRef}
      rowKey="uuid"
      className={cn('active-candidates', grouping.tableData)}
      cardTitle="Active candidates"
      cardCount={count}
      emptyComponent={<ActiveCandidatesTableEmpty />}
      extra={
        <div className="active-candidates__header-extra">
          <Checkbox
            label="Show only with action"
            isSelected={tableOptions.only_with_actions}
            onChange={onChangeWithActions}
          />
          <Divider type="vertical" />
          <div className="filter-container">
            <SelectSmall
              label="Group by"
              options={CANDIDATES_GROUP}
              value={grouping}
              onChange={onGroupingChange}
              placement={PLACEMENTS.BOTTOM_RIGHT}
            />
          </div>
        </div>
      }
      columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][0](tableOptions.sorting, setSorting)}
      loading={loading}
      data={tableData}
      limit={tableOptions.limit}
      page={tableOptions.page}
      count={tableData.reduce((s, d) => s + d.candidates?.length, 0) || tableData.length}
      offset={tableOptions.offset}
      itemsCount={tableOptions.itemsCount}
      tooltipInfo="One candidate may be mentioned more than once (for example, if assigned to more than one job)"
      updateParams={onChangeFilter}
      expandable={
        grouping.tableData && {
          expandedRowKeys,
          expandedRowRender: ({ candidates }) => (
            <>
              <Table
                rowKey="uuid"
                className={cn('active-candidates__sub-table', grouping.tableData)}
                columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1]}
                data={candidates}
                showCardHeader={false}
                showCardFooter={false}
              />
              <TableMobile
                className={cn('active-candidates__sub-table active-candidates__sub-table-mobile', grouping.tableData)}
                data={candidates}
                columns={CANDIDATES_TABLE_COLUMNS[grouping.tableData][1]}
              />
            </>
          ),
          expandIcon: ({ expanded, record }) => (
            <div role="none" className="expanded-row">
              <div className="expanded-row__info">
                <Vector className={cn('expanded-row__icon', { expanded })} onClick={onExpand(record)} />
                <Typography.Text className="name" onClick={() => {}}>
                  {record.name}
                  <span>{record.count}</span>
                </Typography.Text>
                {!!record.extra && <Typography.Text className="extra">{record.extra}</Typography.Text>}
              </div>
            </div>
          ),
        }
      }
      defaultTableMobile={!grouping.tableData}
    />
  );
};

const OpeningsTableEmpty = () => (
  <div className="all-openings__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no openings here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);

const ActiveCandidatesTableEmpty = () => (
  <div className="active-candidates__empty">
    <JobImage />
    <Typography.Title3 weight={TYPOGRAPHY_WEIGHT.BOLD}>You have no active candidates here</Typography.Title3>
    <Typography.Text>We’ll let you know if any shows up or try another filter</Typography.Text>
  </div>
);
